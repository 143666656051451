import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import { Icon } from '../../icon';
import { Button } from '../../ui/button';
import { Modal } from '../../ui/modal';
import styles from './index.module.scss';

const tooltip = {
  usdr: 'Внутренняя монета RSI, которая служит для внутренних взаиморасчетов. 1 USDR = 1 USDT. Средства с USDR счета могут быть переведены на Ваш криптовалютный кошелек (комиссия 3%), а с него уже на любой другой',
  wallet:
    'Ваш собственный криптовалютный кошелек, на данный момент USDT и ETH. С помощью него вы можете переводить криптовалюту на любой другой кошелек соответствующей криптовалюты в разделе WALLET.',
  equity:
    'Величина свободных средств в процентах от общего объема активов фонда.',
};

export const SmartDescription = ({ title = 'Smart', showFooter = true }) => {
  const screenWidth = window.screen.width;

  const [modal, setModal] = useState(false);
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);

  const modalRef = useRef(null);

  const openVideo = url => {
    modalRef.current.style.height = `${
      modalRef.current.clientWidth * (9 / 16)
    }px`;

    setVideoUrl(url);
    setIsVideoVisible(true);
  };

  const closeVideo = () => {
    setVideoUrl(null);
    setIsVideoVisible(false);
  };

  const handleToggleModal = () => setModal(!modal);

  return (
    <>
      <Card>
        <CardHeader className="flex items-center">
          <div className="sm:text-2xl text-lg font-medium">{title}</div>

          <Button
            color="blue"
            className="p-2 ml-3"
            onClick={() =>
              openVideo('https://www.youtube.com/embed/_ElFxKACHw4')
            }
          >
            <Icon name="playButton" color="#fff" size={16} />
          </Button>
        </CardHeader>
        <CardBody className={styles.content}>
          <p className="mb-5 sm:text-md">
            SMART - это авторский инвестиционный продукт, в основе которого
            лежит взаимозаменяемость инструментов согласно трендам рынка (без
            ущерба для инвестора) и алгоритм балансирования рисков и доходности
            таким образом, чтобы обеспечивать инвесторам солидную прибыль вне
            зависимости от тенденции и переменчивости рынка. Согласно нашей
            стратегии, прогнозируемая прибыль для инвестора составляет 55%
            годовых и выше (без учёта капитализации).
          </p>

          <div className="mb-5">
            <b className="text-text">Основа безопасности:</b>
            <ol>
              <li>Диверсификация по инвестиционным инструментам</li>
              <li>Диверсификация по управляющим</li>
              <li>Диверсификация по брокерам</li>
            </ol>
          </div>

          <div className="mb-5">
            <b className="text-text">Защита от потерь:</b>
            <ol>
              <li>
                Контроль рисков - Система автоматического закрытия позиций
                трейдеров при потере 1,5% за день
              </li>
            </ol>
          </div>

          <div className="mb-5">
            <b className="text-text">Финансовое управление:</b>
            <ol>
              <li>Торговля по независимым друг от друга стратегиям</li>
              <li>
                1 положительная сделка покрывает убытки минимум 3-х
                отрицательных
              </li>
            </ol>
          </div>

          <div className="mb-5">
            <b className="text-text">Правила:</b>
            <ol>
              <li>Инвестор получает 50% прибыли, заработанной фондом</li>
              <li>Инвестиция начинает приносить доход на пятый день</li>
              <li>
                Дивиденды начисляются на{' '}
                <a
                  className="tooltip"
                  data-for="tooltipUsdr"
                  data-tip={tooltip.usdr}
                  data-event="click"
                >
                  USDR счет
                </a>{' '}
                по первым рабочим дням каждой недели
              </li>
              <li>
                В случае закрытия инвестиции тело возвращается на{' '}
                <a
                  className="tooltip"
                  data-for="tooltipUsdr"
                  data-tip={tooltip.usdr}
                  data-event="click"
                >
                  USDR счет
                </a>{' '}
                в размере действующего{' '}
                <a
                  className="tooltip"
                  data-for="tooltipEquity"
                  data-tip={tooltip.equity}
                  data-event="click"
                >
                  эквити
                </a>{' '}
                в течение 7 дней
              </li>
              <li>
                Активы начинают работать на 5ый день после совершения
                инвестиции.
              </li>
            </ol>
          </div>
        </CardBody>

        {showFooter && (
          <CardFooter>
            <Button color="primary" onClick={handleToggleModal}>
              Инвестировать
            </Button>
          </CardFooter>
        )}
      </Card>

      <ReactTooltip
        id="tooltipUsdr"
        globalEventOff="click"
        className="tooltip-modal"
        effect="solid"
      />

      <ReactTooltip
        id="tooltipEquity"
        globalEventOff="click"
        className="tooltip-modal"
        effect="solid"
      />

      <Modal
        title="Инструкция инвестиции в Smart"
        onClose={handleToggleModal}
        isOpen={modal}
        footer={
          <Link to="/smart">
            <Button color="primary">Инвестировать</Button>
          </Link>
        }
      >
        <ul>
          <li className="mb-3">
            - Инвестировать можно с USDR счета или криптовалютного кошелька.
            Если у Вас нет USDR, то Вам лучше подойдет способ инвестирования с
            криптовалютного кошелька.
          </li>
          <li className="mb-3">
            - Перед тем, как инвестировать, нужно завести криптовалюту на
            аккаунт. Для этого отправьте на криптовалютный кошелек
            соответствующую криптовалюту. Узнать адреса своих кошельков можно в
            разделе{' '}
            <Link className="link" to="/wallet">
              WALLET
            </Link>
            . Если у Вас нет криптовалюты и Вы не знаете, где можно её
            приобрести, мы рекомендуем Вам{' '}
            <a
              href="https://t.me/RSI_garantBot"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              RSI GARANT Bot
            </a>
            .
          </li>
          <li>
            - После того, как на одном из Ваших кошельков или USDR счёте будет
            сумма, которую вы готовы инвестировать, можете перейти к
            инвестированию.
          </li>
        </ul>
      </Modal>
      <Modal
        isOpen={isVideoVisible}
        modalStyles={{
          maxWidth: 1280,
          width: screenWidth < 600 ? '95%' : '60%',
          margin: 'auto',
        }}
        onClose={closeVideo}
        bodyStyles={{ padding: 6, height: '100%' }}
      >
        <div ref={modalRef}>
          {videoUrl && (
            <iframe
              className="modal-video"
              src="https://www.youtube.com/embed/_ElFxKACHw4"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </div>
      </Modal>
    </>
  );
};
SmartDescription.propTypes = {
  title: PropTypes.string,
  showFooter: PropTypes.bool,
};
