import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Badge } from '../badge';
import { Icon } from '../icon';
import './index.scss';

export const SmartInvest = ({ item }) => {
  return (
    <Link to={`/smart/invest-view/${item.id}`}>
      <Card className="smart-invest">
        <CardBody>
          <div className="sm:text-2xl text-xl font-medium">
            {item?.amount} USDR
          </div>
          <div className="text-sm">{item?.name}</div>

          <Row className="mt-4 mb-6">
            <Col xs={12} sm={6}>
              <Row className="items-center mb-0.5">
                <Col>
                  <div className="flex">
                    <div className="icon p-2 flex items-center justify-center mr-2">
                      <Icon name="statPercent" size={18} color="#fff" />
                    </div>
                    {item.condition != 2 ?
                      <div>
                        Equity:
                        <br />
                        <b>{item.equity}%</b>
                      </div> 
                      :
                      <div>
                       Закрытие по истечении срока
                      </div> 
                    }
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={6}>
              <Row className="items-center sm:mb-0.5">
                <Col>
                  <div className="flex">
                    <div className="icon mr-2 p-2	 flex items-center justify-center">
                      <Icon name="statProfit" size={18} color="#fff" />
                    </div>
                    <div>
                      {' '}
                      Дивиденды:
                      <br />
                      <b>{item.dividendsSum}</b>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-between items-center">
            <Col>
              <Badge color={item.status == 2 ? 'gray' : 'primary'}>
                {item.statusText}
              </Badge>
            </Col>
            <Col xs="auto">{item.createdAt}</Col>
          </Row>
        </CardBody>
      </Card>
    </Link>
  );
};

SmartInvest.propTypes = {
  item: PropTypes.any,
};
