import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { apiPost } from '../../../../api';
import { getActiveClass } from '../../../../helpers/className';
import { useFetch } from '../../../../hooks/useFetch';
import useUrl from '../../../../hooks/useUrl';
import { BillBlock } from '../../../common/billBlock';
import { GarantDescription } from '../../../common/garant/description';
import { GarantInvest } from '../../../common/garantInvest';
// import { GarantPackage } from '../../../common/garantPackage';
// import { Icon } from '../../../common/icon';
import { PageHeader } from '../../../common/pageHeader';
import { Pagination } from '../../../common/pagination';
import { StaticBlock } from '../../../common/staticBlock';
import { Button } from '../../../common/ui/button';
import { Input } from '../../../common/ui/input';
import { Modal } from '../../../common/ui/modal';

// const packageList = [
//   {
//     name: '2%',
//     value: 3000,
//   },
//   {
//     name: '2,25%',
//     value: 5000,
//   },
//   {
//     name: '2,75%',
//     value: 9000,
//   },
//   {
//     name: '3%',
//     value: 'от 15000',
//   },
// ];

export const GarantPage = () => {
  const investBlocked = false;

  const { page } = useUrl();

  const { register, watch, setValue } = useForm();

  const [packageData, setPackageData] = useState(null);
  const [investWallet, setInvestWallet] = useState('ETH');
  const [errObj, setErrObj] = useState({});
  const [alertView, setAlertView] = useState(false);

  const handleAlertOpen = () => setAlertView(true);
  const handleAlertClose = () => setAlertView(false);

  const { response: walletData, fetchData: refreshWallet } = useFetch({
    url: '/address',
  });

  const { response: garantInfo, fetchData: refreshGarantInfo } = useFetch({
    url: '/garant/info',
  });

  const { response: garantInvestList, fetchData: refreshGarantInvestList } =
    useFetch({
      url: `/garant?page=${page || 1}&limit=6`,
    });

  const { response: courseCrypto } = useFetch({ url: '/commissions' });

  const investFromGarant = () => {
    if (investBlocked) {
      return;
    }

    let formData = new FormData();
    if (investWallet) {
      formData.append('source', investWallet);
    }

    formData.append('amount', watch('investAmountCrypto'));
    apiPost({
      url: '/garant/create',
      postData: formData,
    }).then(res => {
      if (res.error) {
        setErrObj(res.data);
        return;
      }
      if (!res.error) {
        setErrObj({});
        handleAlertOpen();
        refreshWallet();
        refreshGarantInfo();
        setPackageData(null);
        setInvestWallet('ETH');
        refreshGarantInvestList();
        setValue('investSumm', '');
        setValue('investAmountCrypto', '');
      }
    });
  };

  const handleChangeBillBlock = value => {
    setInvestWallet(value);
    setValue('investSumm', '');
    setValue('investAmountCrypto', '');
  };

  // const handleChangePackageBlock = value => {
  //   setPackageData(value);
  // };

  useEffect(() => {
    setErrObj({});
  }, [packageData]);

  // const renderPackage = ({ name, value }) => {
  //   return (
  //     <Col key={name} xs={6} sm={3}>
  //       <GarantPackage
  //         name={name}
  //         value={value}
  //         activeClass={getActiveClass(value, packageData)}
  //         onClick={() => handleChangePackageBlock(value)}
  //       />
  //     </Col>
  //   );
  // };

  const renderBillBlock = ({ name, balance }) => {
    let balanced = +balance;
    return (
      <Col key={name} xs={12} sm={6}>
        <BillBlock
          name={name}
          value={name === 'USDR' ? balanced.toFixed(6) : balanced}
          activeClass={getActiveClass(name, investWallet)}
          onClick={handleChangeBillBlock}
        />
      </Col>
    );
  };
  const handleInvestChange = ({ target: { value } }) => {
    if (value === '') {
      setValue('investAmountCrypto', '');
      setValue('investSumm', '');
      return;
    }

    setValue('investSumm', value.replace(/,/g, '.'));
    parseInt(value);
    let comission =
      investWallet === 'USDR'
        ? 1
        : courseCrypto.data[`${investWallet}`].REPLENISH_RATE.value;
    let num = value / comission;
    setValue('investAmountCrypto', num);
  };

  const handleInvestAmountChange = ({ target: { value } }) => {
    if (value === '') {
      setValue('investSumm', '');
      return;
    }
    value = value.replace(/,/g, '.');
    parseInt(value);
    let comission =
      investWallet === 'USDR'
        ? 1
        : courseCrypto.data[`${investWallet}`].REPLENISH_RATE.value;
    let num = value * comission;
    setValue('investSumm', num);
  };

  return (
    <Container fluid>
      <Row className="mt-8">
        <Col xs={12}>
          <Row>
            <Col sm={6} lg={3} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#48337b"
                value={
                  garantInfo?.data?.totalAmount === 0
                    ? garantInfo?.data?.totalAmount
                    : (+garantInfo?.data?.totalAmount).toFixed(6)
                }
                iconName="statHouse"
                title="Общий размер инвестиций"
              />
            </Col>
            <Col sm={6} lg={3} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#f73434"
                value={garantInfo?.data?.totalDividends}
                iconName="statProfit"
                title="Всего получено дивидендов"
              />
            </Col>
            <Col sm={6} lg={3} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#48337b"
                value={garantInfo?.data?.profitPreviousMonth}
                iconName="statDate"
                title="Доход за предыдущий месяц"
              />
            </Col>
            <Col sm={6} lg={3} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#48337b"
                value={garantInfo?.data?.garantCount}
                iconName="statDatabase"
                title="Количество инвестиций"
              />
            </Col>
          </Row>
        </Col>

        {/* {investBlocked ? (
          <div className="col-12">
            <Card>
              <CardBody className="flex-col flex justify-center lg:flex lg:flex-row lg:justify-start items-center">
                <Icon name="warning" size={34} color="#F73434" />
                <div className="ml-4" style={{ maxWidth: '700px' }}>
                  Уважаемые друзья! <br /> К сожалению, инвестиционный пул
                  Garant наполнился намного быстрее, чем мы предполагали, и в
                  данный момент мы не принимаем новых инвестиционных средств.
                  Как только завершится подготовка новых мощностей под следующий
                  пул - мы обязательно Вас оповестим!
                </div>
              </CardBody>
            </Card>
          </div>
        ) : null} */}

        <Col lg={6}>
          <Card>
            <CardHeader>
              <div className="sm:text-2xl text-xl font-medium">
                Инвестиция в Garant
              </div>
            </CardHeader>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="text-lg font-medium mb-2">
                    Пакет 2% от 2000
                  </div>
                </Col>
                <Col xs={12}>
                  <Input
                    label="Размер инвестиции(USDT)"
                    type="number"
                    name="investSumm"
                    register={register}
                    onChange={handleInvestChange}
                  />
                </Col>
                <Col>
                  {errObj?.amount && (
                    <span className="error">{errObj.amount}</span>
                  )}
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <div className="text-lg font-medium mb-2">Счёт списания</div>
                </Col>
                {walletData?.data?.map(renderBillBlock)}
                <Col>
                  {' '}
                  {errObj?.source && (
                    <span className="error">{errObj.source}</span>
                  )}
                </Col>
              </Row>
              {investWallet && (
                <Input
                  label={`Размер инвестиции в ${investWallet}`}
                  register={register}
                  name="investAmountCrypto"
                  onChange={handleInvestAmountChange}
                />
              )}
            </CardBody>

            <CardFooter>
              <Button
                onClick={investFromGarant}
                color={investBlocked ? 'gray' : 'primary'}
              >
                Инвестировать
              </Button>
            </CardFooter>
          </Card>
        </Col>

        <Col lg={6}>
          <GarantDescription title="Что такое Garant?" showFooter={false} />
        </Col>

        <Col xs={12} className="md:mb-8 mb-4">
          {garantInvestList?.data?.models?.length > 0 && (
            <Row>
              <PageHeader title="Инвестиции в Garant" />

              {garantInvestList?.data?.models?.map((data, index) => {
                return (
                  <Col key={index} xs={6} lg={4}>
                    <GarantInvest {...{ data }} />
                  </Col>
                );
              })}
            </Row>
          )}

          <div className="text-center">
            <Pagination
              lastPage={garantInvestList?.data?.lastPage || 1}
              page={page}
            />
          </div>
        </Col>
      </Row>
      <Modal
        title="Инвестиция прошла успешно!"
        onClose={handleAlertClose}
        isOpen={alertView}
        headerStyles={{ border: 'none' }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className="flex justify-center">
            <Col xs={'auto'}>
              <Button onClick={handleAlertClose} color="primary">
                Ок
              </Button>
            </Col>
          </div>
        }
      />
    </Container>
  );
};
GarantPage.propTypes = {};
