import React, { useRef, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { PageHeader } from '../../../common/pageHeader';
import { apiPost } from '../../../../api';
import { Button } from '../../../common/ui/button';
import { Modal } from '../../../common/ui/modal';
import { useFetch } from '../../../../hooks/useFetch';
import { BillBlock } from '../../../common/billBlock';
import Spinner from '../../../common/spinner';
import { getActiveClass } from '../../../../helpers/className';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Icon } from '../../../common/icon';
import { PrometeyVideo } from './PrometeyVideo';
import { Badge } from '../../../common/badge';
import { Checkbox } from '../../../common/checkbox';
import { Input } from '../../../common/ui/input';
import { useForm } from 'react-hook-form';

const PrometeiPage = () => {
  const { register} = useForm();
  const screenWidth = window.screen.width;
  const { response: Prometey, fetchData: refreshPromteyData } = useFetch({
    url: '/prometey',
  });
  const [errObj, setErrObj] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [investWallet, setInvestWallet] = useState('USDR');
  const [Summ, setYearSumm] = useState();
  const [alertView, setAlertView] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [textColor, setTextcolor] = useState('#000');
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [inputVisible, setInputVisible] = useState(false);
  const [promo, setPromo] = useState('');
  const [errObjText,setErrObjText] =useState();
  const modalRef = useRef(null);
  const iconColor = '#7c61bd';
  const { response: courseCrypto } = useFetch({ url: '/commissions' });
  const { response: walletData, fetchData: refreshWalletData } = useFetch({
    url: '/address',
    defaultValue: {},
  });
  const handleAlertOpen = (text, textColor) => {
    setAlertView(true);
    setAlertText(text);
    setTextcolor(textColor);
  };
  const handleAlertClose = () => {
    setAlertView(false);
    setAlertText('');
  };

  const openVideo = url => {
    modalRef.current.style.height = `${
      modalRef.current.clientWidth * (9 / 16)
    }px`;

    setVideoUrl(url);
    setIsVideoVisible(true);
  };

  const closeVideo = () => {
    setVideoUrl(null);
    setIsVideoVisible(false);
  };

  const renderBillBlock = ({ name, balance }) => {
    let balanced = +balance;
    return (
      <Col key={name} xs={12} sm={6}>
        <BillBlock
          name={name}
          value={name === 'USDR' ? balanced.toFixed(6) : balanced}
          activeClass={getActiveClass(name, investWallet)}
          onClick={handleChangeBillBlock}
        />
      </Col>
    );
  };

  const handleChangeBillBlock = value => {
    setInvestWallet(value);
    let sum = Prometey?.data == null ? 1000 : 50;
    let comission =
      value === 'USDR' ? 1 : courseCrypto.data[`${value}`].REPLENISH_RATE.value;
    let num = sum / comission;
    setYearSumm(num);
  };

  const OpenPrometeyModal = () => {
    setModalVisible(true);
    let sum = Prometey?.data == null ? 1000 : 50;
    setYearSumm(sum);
  };
  const handleModalClose = () => {
    setModalVisible(false);
  };

  const BuyPrometey = () => {
    apiPost({
      url: '/prometey/buy',
      postData: {
        source: investWallet,
        promoCode: promo,
      },
    }).then(res => {
      if (res) {
        if (res.error && res.data.promoCode) {
          setErrObjText(res.data.promoCode[0]);
          return;
        } else if (res.error) {
          setErrObj(res.data);
          return;
        }
        setErrObj({});
        refreshWalletData();
        handleModalClose();
        refreshPromteyData();
        handleAlertOpen('Платеж отправлен', '#000');
      }
    });
  };

  const Contuneprometey = () => {
    apiPost({
      url: '/prometey/subscription-payment',
      postData: {
        source: investWallet,
      },
    }).then(res => {
      if (res) {
        if (res.error) {
          setErrObj(res.data);
          return;
        }
        if (!res.error) {
          setErrObj({});
          refreshWalletData();
          handleModalClose();
          refreshPromteyData();
          handleAlertOpen('Платеж отправлен', '#000');
        }
      }
    });
  };
  return (
    <Container fluid>
      <Row>
        <PageHeader title="" />
        <div className="col-12">
          <Card>
            <CardBody className="card-body">
              <Row className="xl:flex-none xl:flex-column flex  items-center">
                <Col lg={12}>
                  <div className="flex items-center sm:mb-6 mb-3">
                    <div className="md:text-4xl sm:text-2xl text-xl  font-medium">
                      PROMETEY
                    </div>
                    {Prometey?.data !== null ? (
                      <Button
                        color="blue"
                        className="p-2 ml-3"
                        onClick={() =>
                          openVideo('https://www.youtube.com/embed/fs2Adi1YVWs')
                        }
                      >
                        <Icon name="playButton" color="#fff" size={16} />
                      </Button>
                    ) : (
                      <></>
                    )}
                  </div>

                  {Prometey?.data !== null ? (
                    <>
                      <></>
                      <br />
                    </>
                  ) : (
                    <PrometeyVideo />
                  )}
                  {Prometey?.data !== null ? (
                    <>
                      <h3 className="text-2xl">Ваш лицензионный ключ:</h3>
                      <div className="flex my-4 flex-wrap items-center sm:text-xs">
                        <span className="text my-1">{Prometey?.data?.key}</span>
                        <CopyToClipboard
                          onCopy={() =>
                            handleAlertOpen('Код успешно скопирован')
                          }
                          text={Prometey?.data?.key}
                        >
                          <button className="ml-3 flex-nowrap flex items-center">
                            <span className="icon flex items-center justify-center">
                              <Icon name="copy" size={22} color={iconColor} />
                            </span>
                          </button>
                        </CopyToClipboard>
                        <span className="text ml-2 my-1">
                          (Действует до: {Prometey?.data?.activeTo})
                        </span>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  <Row className="text-center">
                    {Prometey?.data == null ? (
                      <>
                        <Col xs="auto">
                          <Button
                            className="my-3"
                            onClick={OpenPrometeyModal}
                            color="primary"
                            size="l"
                          >
                            Купить
                          </Button>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col xs="auto">
                          <Button
                            className="my-3"
                            onClick={OpenPrometeyModal}
                            color="primary"
                            size="l"
                          >
                            Продлить
                          </Button>
                        </Col>
                        <Col xs="auto">
                          <a
                            href={Prometey?.data?.inviteToChannelLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button className="my-3" color="primary" size="l">
                              Наш приватный телеграм
                            </Button>
                          </a>
                        </Col>
                        <Col xs="auto">
                          <a
                            href="https://api.rsicrypto.com/prometey/prometey.msi"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button className="my-3" color="primary" size="l">
                              Скачать приложение
                            </Button>
                          </a>
                        </Col>
                      </>
                    )}
                    <Col xs="auto">
                      <a
                        href="http://t.me/prometeycapital"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button className="my-3" color="primary" size="l">
                          Наш телеграм
                        </Button>
                      </a>
                    </Col>
                    <Col xs="auto">
                      <a
                        href="https://api.rsicrypto.com/docs/prometey.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button className="my-3" color="primary" size="l">
                          PDF Презентация 
                        </Button>
                      </a>
                    </Col>
                    {/* <Col xs="auto">
                      <Button className="my-3" color="primary" size="l">
                        Обучение
                      </Button>
                    </Col> */}
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Row>
      <Modal
        title={
          Prometey?.data == null ? 'Стоимость: 1000 USDR' : 'Стоимость: 50 USDR'
        }
        onClose={handleModalClose}
        isOpen={modalVisible}
      >
        {walletData?.status === 200 && (
          <Row>
            {walletData ? (
              walletData?.data?.map(renderBillBlock)
            ) : (
              <Spinner styles={{ margin: 'auto' }} />
            )}
          </Row>
        )}
        <br />
        {Prometey?.data == null ?
          <Checkbox
            checked={inputVisible}
            classNames="mt-4 mb-8"
            register={register}
            name="checkboxCode"
            onChange={e => {
              setInputVisible(e.target.checked);
            }}
          >
            У меня есть промокод.
          </Checkbox>:<></>
        } 
        {inputVisible && (
          <Input   name ="Promocode" register={register} value={promo} onChange={e => setPromo(e.target.value)}></Input>
        )}
        <div className="text-xl">
          Сумма: {Summ} {investWallet}
        </div>
        <br />
        <div className="flex flex-col items-center justify-center">
          <Button
            color="primary"
            size="xl"
            onClick={Prometey?.data == null ? BuyPrometey : Contuneprometey}
          >
            Подтвердить
          </Button>
          {errObj?.source && (
            <div className=" mt-2 error">{errObj?.source}</div>
          )}
          {errObjText && <div className="error">{errObjText}</div>}
        </div>
      </Modal>

      <Modal
        title={alertText}
        onClose={handleAlertClose}
        isOpen={alertView}
        headerStyles={{ border: 'none', color: `${textColor}` }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className="flex justify-center">
            <Col xs={'auto'}>
              <Button onClick={handleAlertClose} color="primary">
                Ок
              </Button>
            </Col>
          </div>
        }
      />
      {Prometey?.data !== null ? (
        <Modal
          isOpen={isVideoVisible}
          modalStyles={{
            maxWidth: 1280,
            width: screenWidth < 600 ? '95%' : '60%',
            margin: 'auto',
          }}
          onClose={closeVideo}
          bodyStyles={{ padding: 6, height: '100%' }}
        >
          <div ref={modalRef}>
            {videoUrl && (
              <iframe
                className="modal-video"
                src="https://www.youtube.com/embed/fs2Adi1YVWs"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            )}
          </div>
        </Modal>
      ) : (
        <></>
      )}
      {Prometey?.data !== null ? (
        <div className="overflow-x-auto my-8">
          <h3 className="text-2xl">График платежей:</h3>
          <table className="table table-fixed">
            <thead>
              <tr>
                <th>№</th>
                <th>ДАТА ПАТЕЖА</th>
                <th className="whitespace-nowrap">СУММА</th>
                <th className="flex flex-col items-center">
                  <>СТАТУС</>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td className="align-middle">1</td>
                <td className="align-middle">
                  {Prometey?.data?.createdAt.split(' ')[0]}
                </td>
                <td className="align-middle whitespace-nowrap">Покупка ключа</td>
                <td className="align-middle flex flex-col items-center">
                  <Badge color="primary">Подтверждено</Badge>
                  <span className="sm:text-xs">
                    {parseInt( Prometey?.data?.subscriptionActiveTo.split(' ')[0].split('.')[1]) -parseInt(Prometey?.data?.createdAt.split(' ')[0].split('.')[1]) == 1 ? (
                      <>
                        {'Заканчивается: ' + Prometey?.data?.subscriptionActiveTo.split(' ')[0]}{' '}
                      </>
                    ) : (
                      <></>
                    )}
                  </span>
                </td>
              </tr>
              {Prometey?.data?.subscriptionPayments.map((i, index) => (
                <tr key={i.id}>
                  <td className="align-middle">{index + 2}</td>
                  <td className="align-middle">{i.plannedPaymentDate}</td>
                  <td className="whitespace-nowrap align-middle ">
                    {i.amount.split('.')[0]}
                  </td>
                  <td className="flex flex-col items-center align-middle">
                    <Badge
                      color={i.factPaymentDate !== null ? 'primary' : 'gray'}
                    >
                      {i.factPaymentDate !== null
                        ? 'Подтверждено'
                        : 'Не подтверждено'}
                    </Badge>
                    <span className="sm:text-xs">
                      {parseInt(
                        Prometey?.data?.subscriptionActiveTo.split(' ')[0].split('.')[1]) - parseInt(i.plannedPaymentDate.split('.')[1]) ==1 ? (
                          <>
                            {'Заканчивается: ' + Prometey?.data?.subscriptionActiveTo.split(' ')[0]}{' '}
                          </>) : (<></>)}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default PrometeiPage;
