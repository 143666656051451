/* eslint-disable indent */
import copy from 'copy-to-clipboard';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { apiGet, apiPost } from '../../../../api';
import { useDeclinationWords } from '../../../../helpers/useDeclarationWords';
import { useFetch } from '../../../../hooks/useFetch';
import {
  updateProfile,
  updateWalletCount,
} from '../../../../store/user/actions';
import { Icon } from '../../../common/icon';
import { StaticBlock } from '../../../common/staticBlock';
import { Button } from '../../../common/ui/button';
import { Input } from '../../../common/ui/input';
import { Modal } from '../../../common/ui/modal';
import './styles.scss';
import { BillBlock } from '../../../common/billBlock';
import { getActiveClass } from '../../../../helpers/className';

export const GuessHashPage = () => {
  const { register, watch } = useForm();

  const profile = useSelector(s => s.user.profile);
  const dispatch = useDispatch();

  const { response: ghCash, fetchData: refreshCash } = useFetch({
    url: '/gh/balances',
    defaultValue: {},
  });
  const { fetchData: refreshWalletData } = useFetch({
    url: '/address',
    defaultValue: {},
  });
  const [alertView, setAlertView] = useState(false);
  const [alertWithdrawView, setAlertWithdrawView] = useState(false);
  const [errObj, setErrObj] = useState({});
  const [dataGh, setDataGh] = useState(null);
  const [viewCode, setViewCode] = useState(false);
  const [copyModal, setCopyModal] = useState(false);
  const [modalSellAllTickets, setModalSellAllTickets] = useState(false);
  const [valueBilletCount, setValueBilletCount] = useState(0);
  const [modalSellAllProfit, setModalSellAllProfit] = useState(false);
  const [errorSellAllProfit, setErrorSellAllProfit] = useState(false);
  const [modalWithdraw, setModalWithdraw] = useState(false);
  const [withdrawWallet, setWithdrawWallet] = useState('USDT');
  const [withdrawSumm, setWitdrawSumm] = useState('');
  const [full, Setfull] = useState(0);
  const changeBilletCount = e => {
    setValueBilletCount(e);
  };

  const handleCloseModalSellAllTickets = () => {
    setModalSellAllTickets(false);
  };

  const handleOpenModalSellAllTickets = () => {
    setModalSellAllTickets(true);
  };

  // const handleOpenModalSellAllProfit = () => {
  //   if (+dataGh?.data?.CountTicketAllReferal < 0.5) {
  //     setErrorSellAllProfit(true);
  //     return;
  //   }
  //
  //   setModalSellAllProfit(true);
  // };

  const renderBillBlock = item => {
    let balanced = item.amount;
    return (
      <Col key={Math.random()} xs={12} sm={6}>
        <BillBlock
          name={item.currency}
          value={item.currency === 'USDR' ? balanced.toFixed(6) : balanced}
          activeClass={getActiveClass(item.currency, withdrawWallet)}
          onClick={handleChangeBillBlock}
        />
      </Col>
    );
  };

  const handleChangeBillBlock = (name, value) => {
    setWithdrawWallet(name);
    Setfull(value);
  };
  const handleCloseModalSellAllProfit = () => {
    setModalSellAllProfit(false);
  };

  const handleCloseErrorSellAllProfit = () => {
    setErrorSellAllProfit(false);
  };
  const sellAllProfit = () => {
    apiPost({ url: '/gh/withdraw-profit' }).then(res => {
      if (res.error) {
        return;
      }
      getInfo();
      handleCloseModalSellAllProfit();
    });
  };

  const { response: ticketPrice, fetchData: refreshTicketPrice } = useFetch({
    url: '/gh/ticket-price',
  });
  const { fetchData: refreshTicketsCount } = useFetch({
    url: '/gh/withdrawal-transactions',
  });

  const sellBillets = () => {
    if (valueBilletCount === 0) return;

    apiPost({
      url: '/gh/burn-ticket',
      postData: {
        count: valueBilletCount,
      },
    }).then(res => {
      if (res.error) {
        console.error('Продажа биллетов');
        return;
      }
      setValueBilletCount(0);
      getInfo();
      getBalanceWallet();
      refreshTicketsCount();
      refreshTicketPrice();
      handleCloseModalSellAllTickets();
    });
  };

  const getBalanceWallet = () => {
    apiGet({
      url: '/address',
    }).then(res => {
      if (res.error) {
        console.error('Получение кошелька');
        return;
      }
      dispatch(
        updateWalletCount(+res.data.filter(i => i.name === 'USDR')[0]?.balance)
      );
    });
  };

  const handlerAlertOpen = () => setAlertView(true);
  const handleAlertClose = () => setAlertView(false);

  const handleWithdrawClose = () => setAlertWithdrawView(false);

  const handleCopyOpen = () => {
    setCopyModal(true);
  };

  const handleCopyClose = () => {
    setCopyModal(false);
  };

  const updateEmail = () => {
    let postData = {};

    if (watch('email')) {
      postData.email = watch('email');
    }

    if (watch('code')) {
      postData.code = watch('code');
    }

    apiPost({
      url: '/user/set-email',

      postData: postData,
    }).then(res => {
      if (res.error) {
        setErrObj(res.data);
        return;
      }
      setErrObj({});
      if (res.data) {
        if (!res.data.emailIsConfirmed) setViewCode(true);
        if (res.data.emailIsConfirmed) {
          dispatch(updateProfile(res.data));
          handleAlertClose();
          getInfo();
          setViewCode(false);
        }
      }
    });
  };

  const downloadFile = data => {
    if (!profile.emailIsConfirmed) {
      handlerAlertOpen();
      return;
    }

    apiGet({ url: '/gh' }).then(res => {
      if (res.error) return;

      getInfo();

      if (data === 'win') {
        window.location = res?.data?.windows;
      }

      if (data === 'lin') {
        window.location = res?.data?.linux;
      }
    });
  };

  const copyReferalLink = () => {
    handleCopyOpen();
    copy(dataGh?.data?.RefLink);
  };

  const getInfo = () => {
    apiGet({ url: '/gh/info' }).then(res => {
      if (res.error) return;

      setDataGh(res.data);
    });
  };

  const renderGhBalance = item => {
    return (
      <div
        key={Math.random()}
        className="col-6 col-md-4 col-xl-3 col-xxl-2 mb-3"
      >
        <div className="rounded-sm bg-bg px-3 py-2 h-full mb-0">
          {item.amount} {item.currency}
        </div>
      </div>
    );
  };
  const openWithdrawModal = () => {
    setModalWithdraw(true);
    Setfull(ghCash?.data[0]?.amount);
  };
  const closeWithdrawModal = () => {
    setModalWithdraw(false);
  };
  useEffect(() => {
    apiGet({ url: '/gh' }).then(() => {
      getInfo();
    });
  }, []);

  const withdraw = () => {
    apiPost({
      url: '/gh/payout',
      postData: {
        currency: withdrawWallet,
        amount: withdrawSumm,
      },
    }).then(res => {
      if (res.error) {
        setErrObj(res.data);
        return;
      }
      closeWithdrawModal();
      setAlertWithdrawView(true);
      refreshCash();
      refreshWalletData();
    });
  };

  return (
    <Container fluid className="guess-page">
      <span className="sm:text-2xl text-lg font-medium mt-6 block ">
        Guess Hash
      </span>

      <Card className="mt-6">
        <CardBody>
          <Row>
            <Col>
              <span className="block mb-5 lg:w-1/2">
                <b>GuessHash</b>. Скачайте приложение для своей ОС.
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm="auto">
              <Button
                className="mt-1"
                color="primary"
                onClick={() => downloadFile('win')}
              >
                Скачать для WINDOWS
              </Button>
            </Col>
            <Col sm="auto">
              <Button
                className="mt-1"
                color="primary"
                onClick={() => downloadFile('lin')}
              >
                Скачать для LINUX
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardHeader className="sm:text-2xl text-lg p-4 xl:p-6 font-medium">
          Баланс монет
          <Button
            className="mt-1 ml-3"
            color="primary"
            onClick={openWithdrawModal}
          >
            Вывести
          </Button>
        </CardHeader>
        <CardBody>
          <div className="row">{ghCash?.data?.map(renderGhBalance)}</div>
        </CardBody>
      </Card>

      {dataGh && profile.emailIsConfirmed ? (
        <Card>
          <CardHeader className="sm:text-2xl text-lg p-6 font-medium">
            Информация
          </CardHeader>
          <CardBody>
            <div className="block text-text border-superGray border rounded-xl mb-6 p-4 border-solid ">
              <div className="row justify-between">
                <div className="col">
                  <div className="font-bold mb-2">Реферальная ссылка</div>
                  <div className="">{dataGh?.data?.RefLink}</div>
                </div>

                <div className="col-sm-auto mt-3 sm:mt-0">
                  <Button
                    onClick={copyReferalLink}
                    color="bg"
                    className="h-full w-full"
                  >
                    <Icon name="copy" />
                  </Button>
                </div>
              </div>
            </div>
            <Row>
              <Col sm={6} md={6} className="md:mb-8 mb-4">
                <StaticBlock
                  itemPos="items-start"
                  classNameBlock="p-5"
                  bg="#48337b"
                  value={dataGh?.data?.CountReferals}
                  iconName=""
                  title="Количество пользователей в структуре"
                />
              </Col>

              <Col sm={6} md={6} className="md:mb-8 mb-4">
                <StaticBlock
                  itemPos="items-start"
                  classNameBlock="p-5"
                  bg="#48337b"
                  value={`${dataGh?.data?.CountTicketAllReferal}`}
                  iconName=""
                  title="Всего намайнено структурой"
                />
              </Col>

              <Col md={12} className="md:mb-8 mb-4">
                <StaticBlock
                  itemPos="items-start"
                  classNameBlock="p-5"
                  bg="#48337b"
                  value={`${
                    dataGh?.data?.CountTicketsAllTime -
                    dataGh?.data?.CountTicketsUsed
                  } ~(${
                    (dataGh?.data?.CountTicketsAllTime -
                      dataGh?.data?.CountTicketsUsed) *
                    ticketPrice?.data
                  }$)`}
                  iconName=""
                  title="Намайнено билетов"
                  btn={{
                    text: 'Продать билеты',
                    func: () => handleOpenModalSellAllTickets(),
                  }}
                />
              </Col>

              {/*<Col md={6} className="md:mb-8 mb-4">*/}
              {/*  <StaticBlock*/}
              {/*    itemPos="items-start"*/}
              {/*    classNameBlock="p-5"*/}
              {/*    bg="#48337b"*/}
              {/*    value={`${dataGh?.data?.Usd}`}*/}
              {/*    iconName=""*/}
              {/*    title="Намайнено структурой"*/}
              {/*    btn={{*/}
              {/*      text: 'Вывести',*/}
              {/*      func: () => handleOpenModalSellAllProfit(),*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</Col>*/}
            </Row>

            {/*{dataGh?.data?.Referals?.length && (*/}
            {/*  <Row>*/}
            {/*    <Col>*/}
            {/*      <div className="text-lg font-medium mb-2 mt-2">*/}
            {/*        Приглашенные рефералы*/}
            {/*      </div>*/}
            {/*    </Col>*/}
            {/*  </Row>*/}
            {/*)}*/}

            {/*<div*/}
            {/*  className="overflow-x-auto overflow-y"*/}
            {/*  style={{*/}
            {/*    maxHeight: 450,*/}
            {/*  }}*/}
            {/*>*/}
            {/*  {dataGh?.data?.Referals?.length && (*/}
            {/*    <table className="table">*/}
            {/*      <thead>*/}
            {/*        <tr>*/}
            {/*          <th>Почта</th>*/}
            {/*        </tr>*/}
            {/*      </thead>*/}
            {/*      <tbody>*/}
            {/*        {dataGh?.data?.Referals?.map((i, index) => (*/}
            {/*          <tr key={index}>*/}
            {/*            <td>{i.Mail || '-'}</td>*/}
            {/*          </tr>*/}
            {/*        ))}*/}
            {/*      </tbody>*/}
            {/*    </table>*/}
            {/*  )}*/}
            {/*</div>*/}
          </CardBody>
        </Card>
      ) : null}

      <Modal
        title="Подтверждение почты"
        onClose={handleAlertClose}
        isOpen={alertView}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div>
            <Button onClick={updateEmail} color="primary">
              Подтвердить
            </Button>
          </div>
        }
      >
        <Row>
          <Col sm={12}>
            <Input
              register={register}
              error={errObj?.email?.[0]}
              name="email"
              label="E-mail"
            />
          </Col>
          {viewCode && (
            <Col sm={12}>
              <Input
                register={register}
                error={errObj?.code?.[0]}
                name="code"
                label="Код с почты"
              />
            </Col>
          )}
        </Row>
      </Modal>
      <Modal
        title="Реферальная ссылка успешно скопирована"
        onClose={handleCopyClose}
        isOpen={copyModal}
        headerStyles={{ border: 'none' }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className="flex justify-center">
            <Col xs={'auto'}>
              <Button onClick={handleCopyClose} color="primary">
                Ок
              </Button>
            </Col>
          </div>
        }
      />

      <Modal
        title="Вы уверенны?"
        onClose={handleCloseModalSellAllProfit}
        isOpen={modalSellAllProfit}
        headerStyles={{ border: 'none' }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className=" row flex justify-center">
            <Col xs={'auto'}>
              <Button onClick={sellAllProfit} color="primary">
                Да
              </Button>
            </Col>

            <Col xs={'auto'}>
              <Button onClick={handleCloseModalSellAllProfit} color="danger">
                Нет
              </Button>
            </Col>
          </div>
        }
      />

      <Modal
        title="Вывод возможен только от 0.5"
        onClose={handleCloseErrorSellAllProfit}
        isOpen={errorSellAllProfit}
        headerStyles={{ border: 'none' }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className=" row flex justify-center">
            <Col xs={'auto'}>
              <Button onClick={handleCloseErrorSellAllProfit} color="primary">
                Ok
              </Button>
            </Col>
          </div>
        }
      />

      <Modal
        title="Продажа билетов"
        onClose={handleCloseModalSellAllTickets}
        isOpen={modalSellAllTickets}
        footer={
          <Button
            onClick={sellBillets}
            color={valueBilletCount === 0 ? 'gray' : 'primary'}
          >
            Продать
          </Button>
        }
      >
        <Slider
          min={0}
          max={
            dataGh?.data?.CountTicketsAllTime - dataGh?.data?.CountTicketsUsed
          }
          tooltip={true}
          value={valueBilletCount}
          onChange={changeBilletCount}
        />

        <div className="">
          Выбранно -{' '}
          <b>
            {valueBilletCount}{' '}
            {useDeclinationWords(valueBilletCount, [
              'билет',
              'билета',
              'билетов',
            ])}
          </b>
        </div>
        <div className="">
          Цена - <b>{valueBilletCount * ticketPrice?.data} USDR</b>
        </div>
      </Modal>
      <Modal
        title="Вывод на счет USDR"
        onClose={closeWithdrawModal}
        isOpen={modalWithdraw}
      >
        {ghCash?.status === 200 && (
          <Row>{ghCash?.data?.map(renderBillBlock)}</Row>
        )}
        <br />
        <Input
          label={withdrawWallet}
          value={withdrawSumm}
          onChange={e => setWitdrawSumm(e.target.value)}
        ></Input>
        <div className="row justify-between mt-1">
          <div className="col-auto">
            Доступно: {parseFloat(full).toFixed(6)}
          </div>

          <div className="col-auto">
            <button
              onClick={() => setWitdrawSumm(parseFloat(full).toFixed(6))}
              className="underline text-purple"
            >
              Макс.
            </button>
          </div>
        </div>
        {errObj?.amount && (
          <div className=" mt-2 error">{errObj?.amount[0]}</div>
        )}
        <br />
        <div className="flex flex-col items-center justify-center">
          <Button color="primary" size="xl" onClick={withdraw}>
            Подтвердить
          </Button>
        </div>
      </Modal>
      <Modal
        title="Успешно"
        onClose={handleWithdrawClose}
        isOpen={alertWithdrawView}
        headerStyles={{ border: 'none' }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className="flex justify-center">
            <Button onClick={handleWithdrawClose} color="primary">
              Ок
            </Button>
          </div>
        }
      />
    </Container>
  );
};
