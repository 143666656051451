// import cx from 'classnames';
import React/*, { useState }*/ from 'react';
// import { Link } from 'react-router-dom';
import { /*Card, CardBody, CardHeader,*/ Container } from 'reactstrap';
// import { Icon } from '../../../common/icon';
// import { PersonalProgress } from '../../../common/personalProgress';
import { LinearTree } from '../../../common/tree/linear';
// import { Button } from '../../../common/ui/button';
import './index.scss';

export const LinearStructure = () => {
  // const [conditionIsOpened, setConditionIsOpened] = useState(false);

  // const handleToggleOpenConditions = () => {
  //   setConditionIsOpened(!conditionIsOpened);
  // };

  return (
    <Container fluid>
      {/* <div className="row">
        <div className="col-12  mt-8">
          <Link to="/smart-in-structure" className="mt-2 w-full">
            <Button className="w-full" color="primary">
              Смарты в структуре
            </Button>
          </Link>
        </div>
      </div>
      <Card className="cmp-progress-table mt-4">
        <CardHeader
          className="p-4 flex items-center justify-between cursor-pointer"
          onClick={handleToggleOpenConditions}
        >
          <div className="sm:text-xl text-text font-medium">
            Условия достижения уровней
          </div>

          <Icon
            name="chevronDown"
            size={20}
            className={cx('transition transform', {
              'rotate-180': conditionIsOpened,
            })}
          />
        </CardHeader>

        <CardBody
          className={cx('overflow-hidden content p-0 h-0', {
            'h-auto': conditionIsOpened,
          })}
        >
          <div className="p-4">
            <PersonalProgress />
          </div>
        </CardBody>
      </Card> */}

      <div className="mt-7 relative">
        <LinearTree />
      </div>
    </Container>
  );
};
