import { AsYouType } from 'libphonenumber-js';
import PropTypes from 'prop-types';
import React, { createRef, forwardRef, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import profileLogo from '../../../assets/images/avatar.svg';
// import logo from '../../../assets/images/logo-horizontal.svg';
import { useFetch } from '../../../hooks/useFetch';
import { Icon } from '../icon';
import './index.scss';

const menuList = [
  // {
  //   path: '/',
  //   text: 'Главная',
  //   icon: 'homeOutline',
  // },
  // {
  //   path: '/smart',
  //   text: 'Smart',
  //   icon: 'smartOutline',
  // },
  // {
  //   path: '/garant',
  //   text: 'Garant',
  //   icon: 'garantOutline',
  // },
  // {
  //   text: 'RSI club',
  //   icon: 'userOutline',
  //   path: '/business-package',
  // },
  // {
  //   path: '/wallet',
  //   text: 'Wallet',
  //   icon: 'walletOutline',
  // },
  // {
  //   path: '/materials',
  //   text: 'Материалы',
  //   icon: 'materialsOutline',
  // },
  {
    path: '/deal',
    text: 'Кабинет партнера',
    icon: 'dealOutline',
    // subCategories: [
    // 	{
    // 		path: "/deal",
    // 		text: "Кабинет партнера",
    // 	},
    // 	{
    // 		path: "/linear-structure",
    // 		text: "Линейная структура",
    // 	},
    // 	{
    // 		path: "/binary-structure",
    // 		text: "Бинарная структура",
    // 	},
    // 	{
    // 		path: "/generations",
    // 		text: "Поколения",
    // 	},
    // ],
  },
  // {
  //   path: '/guesshash',
  //   text: 'GuessHash',
  //   icon: 'miningCrypto',
  //   beta: true,
  // },
  // {
  //   path: '/prometey',
  //   text: 'PROMETEY',
  //   icon: 'fire',
  //   soon: false,
  // },
  // {
  //   path: '/flow',
  //   text: 'RSI flow',
  //   icon: 'handshake',
  //   beta: true,
  // }
];

export const MainMenu = forwardRef(({ backClose }, ref) => {
  const menuListRef = useRef([]);
  menuListRef.current = menuList.map(
    (_, index) => (menuListRef.current[index] = createRef())
  );

  const { response: curatorData } = useFetch({ url: '/user/curator' });
  // const { loading,response: clubData } = useFetch({ url: '/club/view' });

  // if (!loading && clubData?.data !== null && menuList.length < 10) {
  //   menuList.push({
  //     path: '/flow',
  //     text: 'RSI flow',
  //     icon: 'handshake',
  //     beta: true,
  //   });
  // }
  const renderSubcategory = ({ path, text }) => {
    return (
      <NavLink
        activeClassName="_active"
        key={text}
        to={path}
        className="link flex items-center mb-2"
        exact
      >
        {text}
      </NavLink>
    );
  };

  const renderMenuItem = (
    { subCategories, path, icon, text, beta, soon },
    index
  ) => {
    const toggleOpen = () => {
      menuListRef.current[index].current.classList.toggle('_active');
    };

    return (
      <li key={text} ref={menuListRef.current[index]}>
        {subCategories ? (
          <>
            <span
              onClick={toggleOpen}
              key={text}
              className="link flex items-center justify-between cursor-pointer"
            >
              <span className="flex items-center">
                <Icon name={icon} size={20} className="mr-4" />
                {text}
              </span>

              <Icon name="chevronDown" size={16} className="ml-1 arrow" />
            </span>

            <ul>{subCategories.map(renderSubcategory)}</ul>
          </>
        ) : (
          <NavLink
            activeClassName="_active"
            key={text}
            to={path}
            className="link flex items-center justify-between"
            exact
          >
            <span className="flex items-center">
              <Icon name={icon} size={22} className="mr-3" />
              {text}
            </span>

            {beta && <span className="beta">BETA</span>}
            {soon && <span className="beta beta_bg_grey">SOON</span>}
          </NavLink>
        )}
      </li>
    );
  };

  return (
    <>
      <div
        id="main-menu"
        className="scroll-y z-10 flex flex-col justify-between custom-scroll-vertical"
        ref={ref.ref}
      >
        <div>
          <Link to="/" className="block px-4 mt-6 mb-8 logo">
            {/* <img src={logo} /> */}
          </Link>

          <ul className="px-4">{menuList.map(renderMenuItem)}</ul>
        </div>

        {curatorData?.data && (
          <div className="curator w-full">
            <div className="human flex">
              <img
                className="mr-2 w-10 h-10 rounded-md object-cover"
                src={curatorData?.data?.avatar || profileLogo}
              />

              <div className="text-sm">
                <div className="text-xs">ID: {curatorData?.data?.user_id}</div>

                <div className="font-medium leading-tight mt-1">
                  {curatorData?.data?.last_name} {curatorData?.data?.first_name}{' '}
                  {curatorData?.data?.patronymic || ''}
                </div>
              </div>
            </div>

            <div className="line my-3" />

            <div className="row -mx-2 justify-between social-links">
              {curatorData?.data?.phone && (
                <div className="px-2 col-12 mb-3">
                  <a
                    className="link flex items-center no-underline"
                    href={`tel:${curatorData?.data?.phone}`}
                  >
                    <span className="mr-2 phone flex items-center justify-center icon">
                      <Icon color="#fff" size={16} name="phoneLogo" />
                    </span>

                    <span className="hidden lg:block text-text font-medium">
                      {new AsYouType().input(`+${curatorData?.data?.phone}`)}
                    </span>
                  </a>
                </div>
              )}

              {curatorData?.data?.tg && (
                <div className="px-2 col-auto">
                  <a
                    className="link tg flex items-center justify-center icon"
                    href={`https://telegram.me/${
                      curatorData?.data?.tg && curatorData?.data?.tg?.substr(1)
                    }`}
                  >
                    <Icon color="#fff" size={16} name="telegramFill" />
                  </a>
                </div>
              )}

              {curatorData?.data?.fb && (
                <div className="px-2 col-auto">
                  <a
                    className="link fb flex items-center justify-center icon"
                    href={`${curatorData?.data?.fb}`}
                  >
                    <Icon color="#fff" size={16} name="facebookFill" />{' '}
                  </a>
                </div>
              )}

              {curatorData?.data?.sk && (
                <div className="px-2 col-auto">
                  <a
                    className="link sk flex items-center justify-center icon"
                    href={`Skype:${curatorData?.data?.sk}?add`}
                  >
                    <Icon color="#fff" size={16} name="skypeLogo" />{' '}
                  </a>
                </div>
              )}

              {curatorData?.data?.vk && (
                <div className="px-2 col-auto">
                  <a
                    className="link vk flex items-center justify-center icon"
                    href={`https://${curatorData?.data?.vk}`}
                  >
                    <Icon color="#fff" size={16} name="vkontakteFill" />{' '}
                  </a>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div onClick={backClose} className="back-drop-bg" ref={ref.backRef} />
    </>
  );
});
MainMenu.propTypes = {
  backClose: PropTypes.func,
};
MainMenu.displayName = 'MainMenu';
