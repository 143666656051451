import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { apiPost } from '../../../../api';
// import userPoliticy from '../../../../assets/files/terms.pdf';
// import { getActiveClass } from '../../../../helpers/className';
import useUrl from '../../../../hooks/useUrl';
import { appLogin } from '../../../../store/app/actions';
import { updateProfile } from '../../../../store/user/actions';
import Spinner from '../../../common/spinner';
import { Button } from '../../../common/ui/button';
import { Input } from '../../../common/ui/input';
import { AuthLayout } from '../../../layouts/auth';
import './index.scss';

export const LoginPage = ({ history }) => {
  const [authType, /*setAuthType*/] = useState('email');
  const [/*isChecked,*/ setIsChecked] = useState(false);
  const [errObj, setErrObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [authValidation, setAuthValidation] = useState({
    sms: false,
    email: false,
  });

  const { referral } = useUrl();

  const dispatch = useDispatch();

  const { register, watch, handleSubmit } = useForm();

  const checkbox = watch('checkbox');

  const onSubmit = data => {
    setLoading(true);

    // if (!data.checkbox) {
    //   setLoading(false);
    //   return;
    // }

    apiPost({
      url: '/auth/login',
      postData: data,
    })
      .then(res => {
        setLoading(false);

        if (res?.error) {
          setErrObj(res?.data?.data);
        }

        if (res.status === 406) {
          setAuthValidation({
            sms: !!res?.data?.data?.code,
            email: !!res?.data?.data?.emailCode,
          });

          setErrObj(res.data.data);
          return;
        } else if (res.error) {
          alert('Ошибка авторизации');
          return;
        }

        if (res?.data?.data?.access_token) {
          dispatch(updateProfile(res.data.data.profile));
          dispatch(appLogin(res.data.data.access_token));
          history.push('/');
        } else {
          setAuthValidation({
            sms: !!res?.data?.data?.phoneAccepted,
            email: !!res?.data?.data?.emailAccepted,
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const checkShowDescription = () => {
    if (!checkbox) {
      setIsChecked(true);
    }
  };

  return (
    <AuthLayout>
      <Container>
        <Row className="justify-center">
          <Col md="5">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card className="mt-20">
                <CardHeader className="py-3 text-2xl text-center font-medium">
                  Авторизация
                </CardHeader>

                <CardBody>
                  {/* <Row className="mb-6 mx-0 tab-wrap rounded-md">
                    <Col className="px-0">
                      <span
                        className={`button text-center w-full ${getActiveClass(
                          'email',
                          authType
                        )}`}
                        onClick={e => {
                          setAuthType('email');
                          e.preventDefault();
                        }}
                      >
                        E-Mail
                      </span>
                    </Col>

                    <Col className="px-0">
                      <span
                        className={`button text-center w-full ${getActiveClass(
                          'phone',
                          authType
                        )}`}
                        onClick={e => {
                          setAuthType('phone');
                          e.preventDefault();
                        }}
                      >
                        Телефон
                      </span>
                    </Col>
                  </Row> */}

                  {authType === 'email' && (
                    <Input
                      name="username"
                      type="mail"
                      register={register}
                      label="E-Mail"
                      placeholder="example@mail.ru"
                      error={errObj?.username && errObj.username[0]}
                    />
                  )}

                  {authType === 'phone' && (
                    <Input
                      type="tel"
                      register={register}
                      name="username_phone"
                      label="Телефон"
                      error={errObj?.username && errObj.username[0]}
                    />
                  )}

                  <Input
                    name="password"
                    register={register}
                    label="Пароль"
                    type="password"
                    placeholder="• • • • • • • • • • •"
                    error={errObj?.password && errObj.password[0]}
                  />

                  {authValidation.sms && (
                    <Input
                      name="code"
                      register={register}
                      label="Код из смс"
                      error={errObj?.code}
                    />
                  )}

                  {authValidation.email && (
                    <Input
                      name="emailCode"
                      register={register}
                      label="Код с почты"
                      error={errObj?.emailCode}
                    />
                  )}

                  {/* <label className="cursor-pointer mt-8 block">
                    <Row className="items-center">
                      <Col className="col-auto flex pr-0">
                        <input
                          name="checkbox"
                          type="checkbox"
                          className="checkbox"
                          {...register('checkbox')}
                        />
                      </Col>

                      <Col>
                        Ознакомлен с{' '}
                        <a
                          href={userPoliticy}
                          download=""
                          target="_blank"
                          className="text-purple underline"
                          rel="noreferrer"
                        >
                          пользовательским соглашением
                        </a>
                      </Col>
                    </Row>
                  </label> */}

                  {/* {!checkbox && isChecked && (
                    <span className="text-red mt-2 block">
                      *Подтвердите пользовательское соглашение
                    </span>
                  )} */}
                </CardBody>

                <CardFooter>
                  <Row className="items-center">
                    <Col>
                      {loading ? (
                        <Spinner size={70} />
                      ) : (
                        <Button
                          onClick={checkShowDescription}
                          color={checkbox ? 'primary' : 'default'}
                          className={cx({
                            'cursor-default': !checkbox,
                          })}
                        >
                          Войти
                        </Button>
                      )}
                    </Col>

                    <Col xs="auto">
                      <Link
                        to={`/registration${
                          referral ? `?referral=${referral}` : ''
                        }`}
                        className="text-textFade underline"
                      >
                        Регистрация
                      </Link>
                    </Col>

                    <div className="col-auto">
                      <Link
                        to="/reset-pass"
                        className="text-textFade underline"
                      >
                        Забыли пароль?
                      </Link>
                    </div>
                  </Row>
                </CardFooter>
              </Card>

              <div className="mt-3 text-xs text-center">
                В случае возникновения проблем обращайтесь в поддержку на
                электронную почту{' '}
                <a href="mailto:it@rsi.capital?subject=Авторизация">
                  <b>it@wl.ru</b>
                </a>
              </div>
            </form>

            {/* <div className="flex justify-center">
							<Link to="/registration">
								<Button color="primary" size="wide">
									Регистрация
								</Button>
							</Link>
						</div> */}
          </Col>
        </Row>
      </Container>
    </AuthLayout>
  );
};
LoginPage.propTypes = {
  history: PropTypes.object,
};
