import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Col, Row } from 'reactstrap';
import btcImage from '../../../assets/images/purse-bg/btc.png';
import ethImage from '../../../assets/images/purse-bg/eth.png';
import usdrImage from '../../../assets/images/purse-bg/usdr.png';
import usdtImage from '../../../assets/images/purse-bg/usdt.png';
import { Icon } from '../icon';

const imageList = {
  USDT: usdtImage,
  USDR: usdrImage,
  ETH: ethImage,
  BTC: btcImage,
};

export const WalletBLock = ({
  name,
  bill,
  value,
  onClickIn,
  onClickSend,
  onClickOut,
  onClick,
  activeClass,
  copyAddress,
}) => {
  const handleClick = () => {
    onClick(name);
  };

  const iconColor = activeClass === '_active' ? '#7c61bd' : '#000000';

  let num = +value;

  return (
    <div className={cx('wallet-block', activeClass)} onClick={handleClick}>
      
      {name === 'USDT (TRC20)' ? <img src={imageList[name.split(' ')[0]]} className="bg" /> : <img src={imageList[name]} className="bg" />}


      <div className="text-2xl font-medium">
        Счет {name} {name === 'USDT' ? '(ERC20)' : null}
        {name == 'USDT (TRC20)' && <span style={{backgroundColor: '#fff', color: iconColor , padding: 3 , borderRadius: 5, fontSize: 16}}>BETA</span> }
      </div>
      <div className="mt-1 sm:mb-5 flex flex-1 mb-2.5 sm:text text-xs">
        <span>{bill}</span>
      </div>

      <div className="flex items-center">
        <Icon name="circleCheck" color="#fff" size={18} className="mr-3" />
        {name === 'USDR' ? num.toFixed(6) : num}
      </div>

      <div className="line" />

      <Row>
        {name !== 'USDR' && (
          <Col className="col-3 flex flex-col items-center">
            <CopyToClipboard onCopy={copyAddress} text={bill}>
              <button className="flex flex-col items-center">
                <span className="icon flex items-center justify-center">
                  <Icon name="copy" size={22} color={iconColor} />
                </span>
                <span className="text-white sm:text-md text-xs">
                  Скопировать
                </span>
              </button>
            </CopyToClipboard>
          </Col>
        )}

        <Col className="col-3 flex flex-col items-center">
          <button className="flex flex-col items-center" onClick={onClickIn}>
            <span className="icon flex items-center justify-center">
              <Icon name="walletIn" size={22} color={iconColor} />
            </span>
            <span className="text-white sm:text-md text-xs">Пополнить</span>
          </button>
        </Col>

        <Col className="col-3 flex flex-col items-center">
          <button
            className="flex flex-col items-center  sm:text-text text-sm"
            onClick={onClickSend}
          >
            <span className="icon flex items-center justify-center">
              <Icon name="walletSend" size={22} color={iconColor} />
            </span>
            <span className="text-white sm:text-md text-xs">Отправить</span>
          </button>
        </Col>

        {name === 'USDR' && (
          <Col className="col-3 flex flex-col items-center">
            <button
              className="flex flex-col items-center sm:text-text text-sm"
              onClick={onClickOut}
            >
              <span className="icon flex items-center justify-center">
                <Icon name="walletOut" size={22} color={iconColor} />
              </span>
              <span className="text-white sm:text-md text-xs">Вывести</span>
            </button>
          </Col>
        )}
      </Row>
    </div>
  );
};
WalletBLock.propTypes = {
  onClickIn: PropTypes.func,
  onClickSend: PropTypes.func,
  onClickOut: PropTypes.func,
  name: PropTypes.string,
  bill: PropTypes.string,
  value: PropTypes.any,
  onClick: PropTypes.func,
  activeClass: PropTypes.string,
  copyAddress: PropTypes.any,
};
