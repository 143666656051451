import React, { useEffect, useState } from 'react';
import { default as Chart, default as ReactApexChart } from 'react-apexcharts';
import { useForm } from 'react-hook-form';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { apiPost } from '../../../../api';
import { getActiveClass } from '../../../../helpers/className';
import { useFetch } from '../../../../hooks/useFetch';
import useUrl from '../../../../hooks/useUrl';
import { BillBlock } from '../../../common/billBlock';
import { PageHeader } from '../../../common/pageHeader';
import { Pagination } from '../../../common/pagination';
import { SmartDescription } from '../../../common/smart/description';
import { SmartInvest } from '../../../common/smartInvest';
import Spinner from '../../../common/spinner';
import { StaticBlock } from '../../../common/staticBlock';
import { Button } from '../../../common/ui/button';
import { Input } from '../../../common/ui/input';
import { Modal } from '../../../common/ui/modal';
import './index.scss';
import {Select} from '../../../common/ui/select';
const listDataSmartGraph = [
  {
    label: 'Неделя',
    value: 'week',
  },
  {
    label: 'Месяц',
    value: 'month',
  },
];

export const SmartPage = () => {
  const { register, setValue, handleSubmit, watch } = useForm();

  const [investWallet, setInvestWallet] = useState('ETH');
  const [typeClosed,setTypeClosed] =useState(1);
  const [errObj, setErrObj] = useState({});
  const [alertView, setAlertView] = useState(false);
  const [profitSmartGraph, setProfitSmartGraph] = useState([]);
  const [profitSmartGraphName, setProfitSmartGraphName] = useState([]);
  const [profitSmartEquity, setProfitSmartEquity] = useState({
    label: [],
    data: [],
  });
  const handleAlertOpen = () => {
    setAlertView(true);
  };
  const handleAlertClose = () => {
    setAlertView(false);
  };

  const [discreteValue, setDiscretValue] = useState('week');

  const { page, pageNot } = useUrl();

  const { response: smartReportsResponse } = useFetch({
    url: `/smart-report?page=${pageNot || 1}&limit=6`,
  });

  const { response: smartPeriodData } = useFetch({
    url: `/smart-period/period-profit?discreteValue=${discreteValue}&limit=${
      window.innerWidth > 500 ? 10 : 6
    }`,
  });

  const { response: walletData, fetchData: refreshWalletData } = useFetch({
    url: '/address',
    defaultValue: {},
  });

  const { response: courseCrypto } = useFetch({ url: '/commissions' });

  const { response: smartInfoData, fetchData: refreshSmartInfo } = useFetch({
    url: '/smart/info',
  });

  const { response: investSmartData, fetchData: refreshSmartData } = useFetch({
    url: `/smart?page=${page || 1}&limit=6&sort=status`,
  });

  const { response: smartDirectionData } = useFetch({
    url: '/smart-direction',
  });

  const donutChartSeries = smartDirectionData?.data.map(i => {
    return Math.round(((i.profit * i.share) / 100) * 100) / 100;
  });

  const donutChartOptions = {
    chart: {
      width: '100%',
      type: 'donut',
      height: '250px',
    },

    labels: [
      'Маркет мейкер',
      'Торговля краткосрочная',
      'Фондовая торговля',
      'Стейкинг торговый',
      'Стейкинг управляемый',
      'Ликвидность Гарант',
      'Ликвидность',
      'Роботы',
    ],
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360,
      },
    },
    dataLabels: {
      enabled: false,
    },

    fill: {
      type: 'gradient',
    },
    legend: {
      formatter: (val, opts) => {
        return val + ' - ' + opts.w.globals.series[opts.seriesIndex];
      },
      position: 'bottom',
    },
  };

  const barChartSeries = [
    {
      name: 'Equity',
      data: smartDirectionData?.data.map(i => {
        return ((i.share * i.equity) / 100).toFixed(2);
      }),
    },
    {
      name: 'Общий объём',
      data: smartDirectionData?.data.map(i => {
        return i.share.toFixed(2);
      }),
    },
  ];
  const barChartOptions = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    svgParser: false,
    xaxis: {
      categories: [
        'Маркет мейкер',
        'Торговля краткосрочная',
        'Фондовая торговля',
        'Стейкинг торговый',
        'Стейкинг управляемый',
        'Ликвидность Гарант',
        'Ликвидность',
      ],
    },
  };

  const renderBtnChart = b => {
    const clickBtn = () => {
      setDiscretValue(b.value);
    };
    return (
      <Button
        key={b.label}
        className="mr-2 mt-1 sm:mt-0"
        onClick={clickBtn}
        color={b.value === discreteValue ? 'gray' : 'primary'}
      >
        {b.label}
      </Button>
    );
  };

  const handleChangeBillBlock = value => {
    setInvestWallet(value);
  };

  const investToSmart = data => {
    const { investName, investAmount } = data;
    apiPost({
      url: '/smart/create',
      postData: {
        name: investName,
        amount: investAmount,
        source: investWallet,
        condition: typeClosed,
      },
    }).then(res => {
      if (res) {
        if (res.error) {
          setErrObj(res.data);
          return;
        }
        if (!res.error) {
          setErrObj({});
          setInvestWallet(null);
          setValue('investName', '');
          setValue('investAmount', '');
          refreshWalletData();
          refreshSmartData();
          refreshSmartInfo();
          handleAlertOpen();
        }
      }
    });
  };

  const handleInvestChange = ({ target: { value } }) => {
    if (value === '') {
      setValue('investAmountCrypto', '');
      setValue('investAmount', '');
      return;
    }

    setValue('investAmount', value.replace(/,/g, '.'));
    parseInt(value);
    let comission =
      investWallet === 'USDR'
        ? 1
        : courseCrypto.data[`${investWallet}`].REPLENISH_RATE.value;
    let num = value / comission;
    setValue('investAmountCrypto', num);
  };

  const handleInvestAmountChange = ({ target: { value } }) => {
    if (value === '') {
      setValue('investAmount', '');
      return;
    }
    value = value.replace(/,/g, '.');
    parseInt(value);
    let comission =
      investWallet === 'USDR'
        ? 1
        : courseCrypto.data[`${investWallet}`].REPLENISH_RATE.value;
    let num = value * comission;
    setValue('investAmount', num);
  };

  useEffect(() => {
    setValue('investAmount', '');
    setValue('investAmountCrypto', '');
  }, [investWallet]);

  useEffect(() => {
    setProfitSmartGraph(
      smartPeriodData?.data
        ?.map(m => {
          return +m.profit.toFixed(2);
        })
        .reverse()
    );

    setProfitSmartGraphName(
      smartPeriodData?.data
        ?.map(m => {
          return m.name;
        })
        .reverse()
    );

    setProfitSmartEquity({
      label: smartPeriodData?.data
        ?.map(m => {
          return m.name;
        })
        .reverse(),
      data: smartPeriodData?.data
        ?.map(m => {
          return +m.equity.toFixed(2);
        })
        .reverse(),
    });
  }, [smartPeriodData]);

  const renderBillBlock = ({ name, balance }) => {
    let balanced = +balance;
    return (
      <Col key={name} xs={12} sm={6}>
        <BillBlock
          name={name}
          value={name === 'USDR' ? balanced.toFixed(6) : balanced}
          activeClass={getActiveClass(name, investWallet)}
          onClick={handleChangeBillBlock}
        />
      </Col>
    );
  };
  console.log(smartInfoData?.data);
  return (
    <Container fluid>
      <Row className="mt-8">
        <Col lg={6}>
          <Row>
            <Col sm={6} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#48337b"
                value={smartInfoData?.data?.totalAmount + ' USDR'}
                iconName="statHouse"
                title="Общий размер инвестиций"
              />
            </Col>
            <Col sm={6} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#f73434"
                value={smartInfoData?.data?.totalDividends + ' USDR'}
                iconName="statProfit"
                title="Всего получено дивидендов"
              />
            </Col>
            <Col sm={6} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#48337b"
                value={smartInfoData?.data?.profitPreviousMonth + ' USDR'}
                iconName="statDate"
                title="Доход за предыдущий месяц"
              />
            </Col>
            <Col sm={6} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#48337b"
                value={smartInfoData?.data?.smartsCount}
                iconName="statDatabase"
                title="Количество инвестиций"
              />
            </Col>
          </Row>

          <Card>
            <CardHeader>
              <div className="sm:text-2xl text-xl font-medium">
                Инвестиция в Smart
              </div>
            </CardHeader>
            <form onSubmit={handleSubmit(investToSmart)}>
              <CardBody>
                <Input
                  label="Название инвестиции"
                  register={register}
                  name="investName"
                  error={errObj.name}
                />

                <Input
                  label="Размер инвестиции (USDT)"
                  register={register}
                  name="investAmount"
                  error={errObj.amount}
                  defaultValue={0}
                  onChange={handleInvestChange}
                />

                <div className="mb-3">
                  Комиссия - 2%{' '}
                  {watch('investAmount') !== '' &&
                    watch('investAmount') * 0.02 + 'USDT'}
                </div>

                {walletData?.status === 200 && (
                  <Row>
                    {walletData ? (
                      walletData?.data?.map(renderBillBlock)
                    ) : (
                      <Spinner styles={{ margin: 'auto' }} />
                    )}
                  </Row>
                )}

                {errObj?.source && (
                  <div className="error">{errObj?.source}</div>
                )}

                {investWallet && (
                  <Input
                    label={`Размер инвестиции в ${investWallet}`}
                    register={register}
                    name="investAmountCrypto"
                    onChange={handleInvestAmountChange}
                  />
                )}

                <Select 
                  label={'Условие закрытия'}
                  fieldClass="w-full"
                  list={[{label:'Закрытие по Equity',value:1},{label:'Закрытие по истечении срока',value:2}]}
                  name="packageType"
                  register={register}
                  onChange={(e)=>{setTypeClosed(e.target.value);}}
                  value={typeClosed}
                />
                {typeClosed == 2 && <div>При выборе условия закрытия по истечении срока инвестиция будет закрыта автоматически через 2 года. Средства будут возвращены в полном объеме.</div>}
                {/* <Input
									label="Желаемый заработок (USDT)"
									register={register}
									name="wishProfit"
									thumbText="Мы уведомим Вас, когда Вы сможете закрыть инвестицию с таким доходом"
									type="number"
								/> */}
              </CardBody>

              <CardFooter>
                <Button color="primary">Инвестировать</Button>
              </CardFooter>
            </form>
          </Card>

          <Card>
            <CardHeader className="flex justify-between">
              <div className="sm:text-2xl text-xl font-medium">
                Доходность SMART
              </div>
              <div className="flex flex-col sm:flex-row">
                {listDataSmartGraph.map(renderBtnChart)}
              </div>
            </CardHeader>

            <CardBody>
              <div className="current-sale-container">
                <ReactApexChart
                  type="bar"
                  height={250}
                  series={[
                    {
                      name: 'Доход',
                      data: profitSmartGraph,
                    },
                  ]}
                  options={{
                    chart: {
                      // height: 450,
                      type: 'bar',
                    },
                    plotOptions: {
                      bar: {
                        borderRadius: 10,
                        dataLabels: {
                          position: 'top', // top, center, bottom
                        },
                      },
                    },
                    dataLabels: {
                      enabled: true,
                      formatter: function (val) {
                        return val + '%';
                      },
                      offsetY: -20,
                      style: {
                        fontSize: '12px',
                        colors: ['#304758'],
                      },
                    },

                    xaxis: {
                      categories: profitSmartGraphName,
                      position: 'bottom',

                      axisBorder: {
                        show: false,
                      },
                      labels: {
                        rotate: -90,
                      },
                      axisTicks: {
                        show: false,
                      },
                      // crosshairs: {
                      //   fill: {
                      //     type: 'gradient',
                      //     gradient: {
                      //       colorFrom: '#D8E3F0',
                      //       colorTo: '#BED1E6',
                      //       stops: [0, 100],
                      //       opacityFrom: 0.4,
                      //       opacityTo: 0.5,
                      //     },
                      //   },
                      // },
                      tooltip: {
                        enabled: false,
                      },
                    },
                    yaxis: {
                      show: false,
                      labels: {
                        show: false,
                        formatter: function (val) {
                          return val + '%';
                        },
                      },
                    },
                  }}
                />
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader className="flex justify-between">
              <div className="sm:text-2xl text-xl font-medium">
                Эквити SMART
              </div>
              <div className="flex flex-col sm:flex-row">
                {listDataSmartGraph.map(renderBtnChart)}
              </div>
            </CardHeader>

            <CardBody>
              <div className="current-sale-container">
                <ReactApexChart
                  type="bar"
                  height={250}
                  series={[
                    {
                      name: 'Equity',
                      data: profitSmartEquity.data,
                    },
                  ]}
                  options={{
                    chart: {
                      // height: 450,
                      type: 'bar',
                    },
                    plotOptions: {
                      bar: {
                        borderRadius: 10,
                        dataLabels: {
                          position: 'top', // top, center, bottom
                        },
                      },
                    },
                    dataLabels: {
                      enabled: true,
                      formatter: function (val) {
                        return val + '%';
                      },
                      offsetY: -20,
                      style: {
                        fontSize: '12px',
                        colors: ['#F73434'],
                      },
                    },
                    colors: ['#F73434'],
                    xaxis: {
                      categories: profitSmartEquity.label,
                      position: 'bottom',

                      axisBorder: {
                        show: false,
                      },
                      labels: {
                        rotate: -90,
                      },
                      axisTicks: {
                        show: false,
                      },

                      // crosshairs: {
                      //   fill: {
                      //     type: 'gradient',
                      //     gradient: {
                      //       colorFrom: '#D8E3F0',
                      //       colorTo: '#BED1E6',
                      //       stops: [0, 100],
                      //       opacityFrom: 0.4,
                      //       opacityTo: 0.5,
                      //     },
                      //   },
                      // },
                      tooltip: {
                        enabled: false,
                      },
                    },
                    yaxis: {
                      show: false,
                      labels: {
                        show: false,
                        formatter: function (val) {
                          return val + '%';
                        },
                      },
                    },
                  }}
                />
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col lg={6}>
          <SmartDescription title="Что такое Smart?" showFooter={false} />

          <Card>
            <CardBody>
              {donutChartSeries?.length > 0 && (
                <Row>
                  <Col>
                    <div className="sm:text-2xl text-xl font-medium">
                      Доходность за предыдущую неделю
                    </div>
                  </Col>

                  <Col lg={12}>
                    <Chart
                      options={donutChartOptions}
                      series={donutChartSeries}
                      type="donut"
                      width="100%"
                    />
                  </Col>

                  <Col lg={12}>
                    <Chart
                      options={barChartOptions}
                      series={barChartSeries}
                      type="bar"
                      width="100%"
                    />
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>

        {investSmartData?.data?.models.length > 0 && (
          <Col xs={12}>
            <Row>
              <PageHeader title="Инвестиции в Smart" />

              {investSmartData?.data?.models.map((item, index) => {
                return (
                  <Col key={index} xs={12} sm={6} xl={4}>
                    <SmartInvest {...{ item }} />
                  </Col>
                );
              })}
            </Row>
            <Row>
              {' '}
              <div className="justify-center m-auto">
                <Pagination
                  lastPage={investSmartData?.data?.lastPage}
                  page={page}
                />
              </div>
            </Row>
          </Col>
        )}

        <Col xs={12}>
          <div className="row mt-6">
            <PageHeader title="Отчетность" />

            {smartReportsResponse?.data?.models.map((i, index) => {
              return (
                <div className="col-sm-6 col-lg-4 col-xxl-3" key={index}>
                  <a href={i.url}>
                    <Card className="_sm">
                      <CardBody>
                        <div className="mb-2">{i.name}</div>

                        <Button size="xs" color="primary">
                          Скачать
                        </Button>
                      </CardBody>
                    </Card>
                  </a>
                </div>
              );
            })}
          </div>

          <div className="mb-7 flex justify-end">
            <Pagination
              lastPage={smartReportsResponse?.data?.lastPage}
              page={pageNot}
              nameGet={'pageNot'}
            />
          </div>
        </Col>
      </Row>

      <Modal
        title="Инвестиция прошла успешно"
        onClose={handleAlertClose}
        isOpen={alertView}
        headerStyles={{ border: 'none' }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className="flex justify-center">
            <Button onClick={handleAlertClose} color="primary">
              Ок
            </Button>
          </div>
        }
      />
    </Container>
  );
};
