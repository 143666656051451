import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { apiPost } from '../../../../../api';
import { useFetch } from '../../../../../hooks/useFetch';
import useUrl from '../../../../../hooks/useUrl';
import { Pagination } from '../../../../common/pagination';
import { StaticBlock } from '../../../../common/staticBlock';
import { Button } from '../../../../common/ui/button';
import { Input } from '../../../../common/ui/input';
import { Modal } from '../../../../common/ui/modal';

export const GarantInvestViewPage = () => {
  const { page } = useUrl();

  const { id } = useParams();
  const { response: garantData, fetchData: refreshGarantData } = useFetch({
    url: `/garant/view?id=${id}`,
  });

  const { response: divedentsData } = useFetch({
    url: `/garant-dividends?id=${id}&page=${page || 1}&limit=20`,
  });

  const { register, watch, setValue } = useForm();

  const [removeInvestModal, setRemoveInvestModal] = useState(false);
  const [validate, setValidate] = useState({ sms: false, email: false });
  const [alertView, setAlertView] = useState(false);

  const handleAlertOpen = () => setAlertView(true);
  const handleAlertClose = () => setAlertView(false);

  const handleOpenRemoveInvestModal = () => {
    setRemoveInvestModal(true);
  };

  const handleCloseRemoveInvestModal = () => {
    setRemoveInvestModal(false);
  };

  const handleAcceptRemoveInvestModal = () => {
    let formData = new FormData();
    if (validate.sms) {
      formData.append('code', watch('smsCode'));
    }
    if (validate.email) {
      formData.append('emailCode', watch('emailCode'));
    }
    apiPost({
      url: `/garant/close?id=${id}`,
      postData: formData,
    }).then(res => {
      if (res.status === 406) {
        return;
      }
      if (!res.error) {
        if (res.data === true) {
          handleAlertOpen();
          setRemoveInvestModal(false);
          refreshGarantData();
        } else {
          setValidate({
            sms: res.data.needPhoneConfirmation,
            email: res.data.needEmailConfirmation,
          });
        }
      }
    });
  };

  useEffect(() => {
    if (garantData?.data?.close_on_equity) {
      setValue('settingValue', garantData?.data?.close_on_equity);
    }
  }, [garantData]);

  return (
    <>
      <Container fluid>
        <Row className="my-8 justify-between">
          <Col>
            <div className="md:text-2xl text-lg font-medium">
              Инвестиция в garant №{id}
            </div>
          </Col>

          {garantData?.data?.status === 1 && (
            <Col xs="auto">
              <Button
                color="danger"
                size="sm"
                onClick={handleOpenRemoveInvestModal}
              >
                Закрыть
              </Button>
            </Col>
          )}
        </Row>

        <Row>
          <Col className="mb-2" sm={6} lg={3}>
            <StaticBlock
              bg="#48337b"
              value={`${
                garantData?.data?.amount &&
                Number(garantData?.data?.amount).toFixed(6)
              }`}
              iconName="statHouse"
              title="Размер инвестиции"
            />
          </Col>

          <Col className="mb-2" sm={6} lg={3}>
            <StaticBlock
              bg="#48337b"
              value={`${garantData?.data?.percent}%`}
              iconName="statEquity"
              title="Процент"
            />
          </Col>
          <Col className="mb-2" sm={6} lg={3}>
            <StaticBlock
              bg="#48337b"
              value={`${garantData?.data?.createdAt}`}
              iconName="statDate"
              title="Дата инвестирования"
            />
          </Col>

          <Col xs={12}>
            <Card>
              <CardHeader>
                <div className="text-2xl font-medium">
                  История начислений дивидендов
                </div>
              </CardHeader>
              <CardBody>
                <div className="overflow-x-auto">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Дата начисления</th>
                        <th>Сумма начисления</th>
                        <th>Процент</th>
                      </tr>
                    </thead>
                    <tbody>
                      {divedentsData?.data?.models?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.createdAt}</td>
                            <td>{item.amount}</td>
                            <td>{item.percent}%</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <Pagination
                    lastPage={divedentsData?.data?.lastPage || 1}
                    page={page}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        title="Закрытие инвестиции"
        onClose={handleCloseRemoveInvestModal}
        isOpen={removeInvestModal}
        footer={
          <Row className="justify-end">
            <Col xs="auto">
              <Button
                color="primary"
                size="sm"
                onClick={handleAcceptRemoveInvestModal}
              >
                Подтвердить
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                color="danger"
                size="sm"
                onClick={handleCloseRemoveInvestModal}
              >
                Отменить
              </Button>
            </Col>
          </Row>
        }
      >
        <p>Вы действительно хотите закрыть инвестицию?</p>

        <div className="mt-2" />

        {validate.sms && (
          <Input label="Sms - код" name="smsCode" register={register} />
        )}
        {validate.email && (
          <Input label="Email - код" name="emailCode" register={register} />
        )}
      </Modal>

      <Modal
        title="Инвестиция успешно закрыта"
        onClose={handleAlertClose}
        isOpen={alertView}
        headerStyles={{ border: 'none' }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className="flex justify-center">
            <Col xs={'auto'}>
              <Button onClick={handleAlertClose} color="primary">
                Ок
              </Button>
            </Col>
          </div>
        }
      />
    </>
  );
};
