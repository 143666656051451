const updateUrl = ({ key, value, clear = false } = {}) => {
  const matchRule = new RegExp(`${key}=[,-а-яА-Я0-9a-zA-Z%]+`, 'g');
  let { href, protocol, hostname, pathname, hash, search } = window.location;
  const initUrl = `${protocol}//${hostname}${pathname}`;

  let newLink;

  if (!clear) {
    if (search.length > 0) {
      newLink = search.match(matchRule)
        ? href.replace(matchRule, `${key}=${value}`)
        : `${initUrl}${search}&${key}=${value}`;
    } else {
      newLink = `${initUrl}?${key}=${value}${hash}`;
    }
  } else {
    newLink = `${initUrl}${hash}`;
  }

  return newLink.replace(`${protocol}//${hostname}`, '');
};

export default updateUrl;
