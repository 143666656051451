import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Container } from 'reactstrap';
import updateUrl from '../../../../hooks/updateUrl';
import { useFetch } from '../../../../hooks/useFetch';
import useUrl from '../../../../hooks/useUrl';
import { Pagination } from '../../../common/pagination';
import Spinner from '../../../common/spinner';
import { Select } from '../../../common/ui/select';
import './index.scss';

export const SmartInStructure = () => {
  const { page } = useUrl();
  const history = useHistory();

  const { register, watch } = useForm();
  const [filterStatusData, setFilterStatusData] = useState([
    { label: 'Выбрать', value: '' },
  ]);

  const { response: smartInStructureData, loading: loadingSmartInStructure } =
    useFetch({
      url: `/smart/structure?page=${page || 1}&limit=10&status=${
        watch('filterStatus') || ''
      }`,
    });

  const { response: filterSmartStruture } = useFetch({
    url: '/smart/get-search-attributes',
  });

  useEffect(() => {
    if (filterSmartStruture) {
      let arr = filterSmartStruture?.data?.status?.list.map((s, index) => {
        return { label: s, value: index };
      });

      arr.unshift({ label: 'Выбрать', value: '' });

      setFilterStatusData(arr);
    }
  }, [filterSmartStruture]);

  useEffect(() => {
    history.push(updateUrl({ key: 'page', value: '1' }));
  }, [watch('filterStatus')]);

  const renderTableRows = (data, index) => {
    return (
      <tr key={index}>
        <td>{data?.id}</td>
        <td>{data?.user}</td>
        <td>{data?.amount}</td>
        <td>{data?.createdAt}</td>
        <td>{data?.statusText}</td>
        <td>{data?.dividendsSum}</td>
      </tr>
    );
  };

  return (
    <Container fluid>
      <div className="md:flex justify-between">
        <div className="sm:text-2xl my-5 md:my-10 text-lg font-medium flex ">
          Смарты в структуре
        </div>
        <div className="flex items-center mb-2 md:mb-0">
          <Select
            fieldClass="m-0"
            label="Статус"
            list={filterStatusData}
            name="filterStatus"
            register={register}
          />
        </div>
      </div>

      <Card>
        <CardBody className="overflow-auto">
          {loadingSmartInStructure ? (
            <Spinner styles={{ margin: 'auto' }} />
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th>№</th>
                  <th>ПОЛЬЗОВАТЕЛЬ</th>
                  <th>СУММА</th>
                  <th>ДАТА ИНВЕСТИРОВАНИЯ</th>
                  <th>СТАТУС</th>
                  <th>НАЧИСЛЕНО</th>
                </tr>
              </thead>
              <tbody>
                {smartInStructureData?.data?.models.map(renderTableRows)}
              </tbody>
            </table>
          )}
        </CardBody>
        <div className="text-center">
          <Pagination
            lastPage={smartInStructureData?.data.lastPage}
            page={page}
          />
        </div>
      </Card>
    </Container>
  );
};
