/* eslint-disable indent */
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { apiPost, apiGet } from '../../../../api';
import img1 from '../../../../assets/images/rsi-club/1.png';
import { useFetch } from '../../../../hooks/useFetch';
import { Checkbox } from '../../../common/checkbox';
import { Icon } from '../../../common/icon';
import Spinner from '../../../common/spinner';
import { StaticBlock } from '../../../common/staticBlock';
import { Button } from '../../../common/ui/button';
import { Modal } from '../../../common/ui/modal';
import { Select } from '../../../common/ui/select';
import './index.scss';
import { Input } from '../../../common/ui/input';

const buyList = [
  {
    label: '1 месяц - 100 USDR',
    value: 'month',
  },
  {
    label: '6 месяцев - 500 USDR',
    value: '6month',
  },
  {
    label: '1 год - 800 USDR',
    value: 'year',
  },
];

const textList = [
  { text: 'Обучающие курсы ', icon: 'machineLearning' },
  { text: 'Сообщество единомышленников ', icon: 'investor' },
  { text: 'Закрытый канал', icon: 'channel' },
  { text: 'Торговые системы', icon: 'moneySystem' },
];

export const RsiClubPage = () => {
  const screenWidth = window.screen.width;
  const location = useLocation();
  const history = useHistory();

  const [errObj, setErrObj] = useState({});
  const [errObjText, setErrObjText] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [rsiClubModalVisible, setRsiClubModalVisible] = useState(false);
  const [alertView, setAlertView] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const[innerButton,SetinnerButton] = useState('Скачать методичку');
  const[buttonDisabled,setButtonDisabled] = useState(false);
  const[inputVisible,setInputVisible] = useState(false);

  const handleAlertOpen = () => setAlertView(true);
  const handleAlertClose = () => setAlertView(false);

  const {
    response: clubDataView,
    fetchData: refreshClubViewData,
    loading: loadingDataClub,
  } = useFetch({
    url: '/club/view',
  });

  const modalRef = useRef(null);

  const goToTraining = () => {
    history.push('/training');
  };

  const goToTrading = () => {
    history.push('/trading-with-rsi');
  };

  const { register, handleSubmit } = useForm();

  const openVideo = url => {
    modalRef.current.style.height = `${
      modalRef.current.clientWidth * (9 / 16)
    }px`;

    setVideoUrl(url);
    setIsVideoVisible(true);
  };

  const closeVideo = () => {
    setVideoUrl(null);
    setIsVideoVisible(false);
  };

  // const viewAlertWorking = () => {
  //   setAlertText('Раздел в разработке');
  //   setAlertView(true);
  // };

  const viewBuySubscribe = text => {
    setAlertText(text);
    setAlertView(true);
  };

  const handleModalOpen = () => {
    setModalVisible(true);
  };
  const handleModalClose = () => {
    setModalVisible(false);
  };

  const handleRsiClubModalOpen = () => {
    setRsiClubModalVisible(true);
  };
  const handleRsiClubModalClose = () => {
    setRsiClubModalVisible(false);
  };

  const handleAcceptPartnerContract = ({ checkbox, packageType,Promocode,checkboxCode}) => {
    setErrObj({});
    setErrObjText('');
    if (!checkbox) {
      setErrObj({ chErr: 'Необходимо подтвердить согласие' });
      return;
    }else if(checkbox && checkboxCode){
      apiPost({
      url: '/club/buy',
      postData: { period: packageType, promoCode:Promocode },
    }).then(res => {
      if (res) {
        if (res.error && res.data.promoCode) {
          setErrObjText(res.data.promoCode[0]);
          return;
        }else if(res.error){
          setErrObjText(res.data);
          return;
        }
        refreshClubViewData();
        setAlertText('Покупка успешно совершена');
        handleAlertOpen();
        setModalVisible(false);
        setErrObj({});
      }
    });
    }else{
      apiPost({
        url: '/club/buy',
        postData: { period: packageType, promoCode:Promocode },
      }).then(res => {
        if (res) {
          if (res.error) {
            setErrObjText(res.data);
            return;
          }
          refreshClubViewData();
          setAlertText('Покупка успешно совершена');
          handleAlertOpen();
          setModalVisible(false);
          setErrObj({});
        }
      });
    }
    
  };

  const renderTextItem = i => {
    return (
      <Col md={6} sm={6} xs={12} className="md:mb-7 mb-3" key={i.text}>
        <Card className="h-full m-0">
          <CardBody className="p-4 h-full  flex items-center ">
            <span className="ml-2">
              <Icon size={30} name={i.icon} />
            </span>
            <div
              className="text-lg ml-4"
              dangerouslySetInnerHTML={{ __html: i.text }}
            />
          </CardBody>
        </Card>
      </Col>
    );
  };

  const downloadManual = () => {
    SetinnerButton(
      <Spinner styles={{ margin: 'auto' }} size={27} color="#fff" />
    );
    setButtonDisabled(true);
    apiGet({
      url: '/club/download-manual',
    })
      .then(res => {
        if (res.error) {
          console.error(res.error);
          return;
        }
        const downloadLink = document.createElement('a');
        downloadLink.href = res.data.link;
        downloadLink.download = 'manual.pdf';
        downloadLink.target ='_blank';
        downloadLink.click();
     SetinnerButton('Загружено');
    });
  };

  return (
    <>
      <Container fluid>
        <Card className="mt-7">
          <CardBody>
            <div className="row mb-8">
              <div className="col-auto">
                <Button
                  color={
                    location.pathname === '/business-package'
                      ? 'primary'
                      : 'gray'
                  }
                  onClick={() => history.push('/business-package')}
                >
                  Уровень 1
                </Button>
              </div>

              <div className="col-auto">
                <Button
                  color={
                    location.pathname === '/courses-level2' ? 'primary' : 'gray'
                  }
                  onClick={() => history.push('/courses-level2')}
                >
                  Уровень 2
                </Button>
              </div>
            </div>

            <Row>
              <Col xl={8}>
                <div className="flex items-center sm:mb-6 mb-3">
                  <div className="md:text-4xl sm:text-2xl text-xl  font-medium">
                    RSI club
                  </div>

                  <Button
                    color="blue"
                    className="p-2 ml-3"
                    onClick={() =>
                      openVideo('https://www.youtube.com/embed/n6zHuRfxBTc')
                    }
                  >
                    <Icon name="playButton" color="#fff" size={16} />
                  </Button>
                </div>

                <p className="sm:text-md text-text">
                  {/* Торгуйте и зарабатывайте вместе с ведущими управляющими фонда
                  <br />
                  <br />
                  Находясь в RSI club вы получаете поддержку, эксклюзивную
                  информацию, доступ в закрытое сообщество с нашими
                  управляющими, а так же возможность первыми использовать
                  изменчивость рынка ценных бумаг и криптовалюты по нашим
                  сигналам. */}
                  RSI Клуб - это возможность обучаться и зарабатывать с ведущими
                  аналитиками и трейдерами RSI по подписке.
                  <br />
                  <br />
                  Цена подписки: <br />
                  100$ за месяц, <br />
                  500$ за 6 месяцев, <br />
                  <b> 800$ за 12 месяцев.</b>
                  <br />
                  <br />
                  Обучающие курсы:
                  <br />
                  <br />
                  <b>Разумный инвестор*</b> - базовая финансовая грамотность.
                  <br />
                  <b>Портфельный инвестор*</b> - курс по составлению
                  криптопортфеля "в долгую".
                  <br />
                  <b>Активный инвестор*</b> - обучающий материал по
                  самостоятельной торговле на криптовалютном рынке.
                  <br />
                  <br />
                  Сообщество:
                  <br />
                  <br />
                  <b>Доступ к закрытому TG каналу**</b>
                  <span className="italic">
                    (сигналы, разбор криптопортфеля, рекомендации по покупке и
                    продаже активов, закрытые пресейлы интересных токенов,
                    прямые эфиры с трейдерами, общение, аналитика рынков)
                  </span>
                  <br />
                  <b>Закрытые мероприятия**</b>
                  <span className="italic">
                    ("тусовка трейдеров", "инвесторский weekend", итд)
                  </span>
                  <br />
                  <br />
                  <span className="italic">
                    {' '}
                    *Материалы доступны на любом пакете подписки
                    <br />
                    **Доступно{' '}
                    <b>только членам клуба на годовом абонементе (800$)</b>
                  </span>
                </p>

                <div className="mt-12">
                  <Row className="items-center">
                    <Col xs="auto">
                      <Button
                        color="primary"
                        size="xl"
                        onClick={handleRsiClubModalOpen}
                      >
                        Как войти в RSI club?
                      </Button>
                    </Col>

                    <Col xs="auto">
                      <Button
                        color="primary"
                        size="xl"
                        onClick={handleModalOpen}
                      >
                        {clubDataView?.data ? 'Продлить' : 'Активировать'}
                      </Button>
                    </Col>
                    {clubDataView?.data ? <Col xs="auto">
                      <Button onClick={downloadManual}
                        className='my-3'
                        color={innerButton ==='Загружено'? 'gray' :'primary'}
                        size="xl"
                        disabled={buttonDisabled}
                      >
                       {innerButton}
                      </Button>
                    </Col> : ''}
                    

                    {/* <Col xs="auto sm:text-lg text-text">
                      <b>800 USDR</b> на один год
                    </Col> */}
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      {clubDataView?.data?.activeTo && (
                        <div className="sm:text-xl text-text">
                          Активно до: {clubDataView?.data?.activeTo}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col className="lg:block hidden" xl={4}>
                <img src={img1} alt="" />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Row className="text-base text-text text-center">
          {textList.map(renderTextItem)}
        </Row>

        {loadingDataClub ? (
          <Spinner styles={{ margin: 'auto' }} />
        ) : (
          <Row className="mb-8 mt-14">
            <div
              onClick={() =>
                clubDataView?.data
                  ? goToTraining()
                  : viewBuySubscribe(
                      'Для активации пожалуйста приобретите подписку клуба'
                    )
              }
              className="lg:mb-0 mb-4 cursor-pointer col-sm-6 col-lg-6"
            >
              <StaticBlock
                bg={clubDataView?.data ? '#7c61bd' : '#A2A2A2'}
                value="Обучение"
                iconName="study"
              />
            </div>

            {/*<div*/}
            {/*  onClick={viewAlertWorking}*/}
            {/*  className="lg:mb-0 mb-4 cursor-pointer col-sm-6 col-lg-4"*/}
            {/*>*/}
            {/*  <StaticBlock*/}
            {/*    bg={*/}
            {/*      clubDataView?.data?.inviteToChannelLink*/}
            {/*        ? '#7c61bd'*/}
            {/*        : '#a2a2a2'*/}
            {/*    }*/}
            {/*    value="PROMETEY"*/}
            {/*    iconName="generalUser"*/}
            {/*  />*/}
            {/*</div>*/}

            <div
              onClick={() =>
                clubDataView?.data?.inviteToChannelLink
                  ? goToTrading()
                  : viewBuySubscribe(
                      'Для активации пожалуйста приобретите подписку клуба на год'
                    )
              }
              className="lg:mb-0 mb-4 cursor-pointer col-sm-6 col-lg-6"
            >
              <StaticBlock
                bg={
                  clubDataView?.data?.inviteToChannelLink
                    ? '#7c61bd'
                    : '#a2a2a2'
                }
                value="Торговля с RSI"
                iconName="portfolio"
              />
            </div>
          </Row>
        )}
      </Container>

      <Modal
        title="Инструкция"
        onClose={handleRsiClubModalClose}
        isOpen={rsiClubModalVisible}
      >
        Счет USDR можно пополнить только с криптовалютного кошелька. Для этого
        отправьте на криптовалютный кошелек соответствующую криптовалюту.
        <br />
        <br />
        Узнать адреса своих кошельков можно в разделе{' '}
        <Link className="link" to="/wallet">
          WALLET
        </Link>
        . Если у Вас нет криптовалюты и Вы не знаете, где можно её приобрести,
        мы рекомендуем Вам{' '}
        <a
          href="https://t.me/RSI_garantBot"
          target="_blank"
          rel="noreferrer"
          className="link"
        >
          RSI GARANT Bot
        </a>
        .
        <br />
        <br />
        Когда на Ваш криптовалютный кошелек поступят средства, Вы сможете
        совершить обмен криптовалюты на USDR.
      </Modal>
{/*  */}
      <Modal
        title="Покупка бизнес-места"
        onClose={handleModalClose}
        isOpen={modalVisible}
      >
        <form onSubmit={handleSubmit(handleAcceptPartnerContract)}>
          <Select list={buyList} name="packageType" register={register} />

          <Checkbox classNames="mt-4 mb-8" name="checkbox" register={register}>
            Вы заключаете партнерский контракт с компанией
          </Checkbox>
          <Checkbox checked={inputVisible} classNames="mt-4 mb-8" name="checkboxCode" register={register} onChange={(e)=>{setInputVisible(e.target.checked);}}>
            У меня есть промокод.
          </Checkbox>
          {inputVisible &&   <Input
          name ="Promocode"
          register={register}
          ></Input>}
           {errObj?.chErr && <div className="error">{errObj?.chErr}</div>}
          {errObjText && <div className="error">{errObjText}</div>}
          <Button className="w-full" color="primary">
            Активировать бизнес-место
          </Button>
        </form>
      </Modal>
      <Modal
        isOpen={isVideoVisible}
        modalStyles={{
          maxWidth: 1280,
          width: screenWidth < 600 ? '95%' : '60%',
          margin: 'auto',
        }}
        onClose={closeVideo}
        bodyStyles={{ padding: 6, height: '100%' }}
      >
        <div ref={modalRef}>
          {videoUrl && (
            <iframe
              className="modal-video"
              src="https://www.youtube.com/embed/n6zHuRfxBTc"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </div>
      </Modal>
      <Modal
        title={alertText}
        onClose={handleAlertClose}
        isOpen={alertView}
        headerStyles={{ border: 'none' }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className="flex justify-center">
            <Col xs={'auto'}>
              <Button onClick={handleAlertClose} color="primary">
                Ок
              </Button>
            </Col>
          </div>
        }
      />
    </>
  );
};
