import bulatImg from '../assets/images/bulat.png';
import newTool from '../assets/images/NewTool.png';
export const courseDesList = {
  1: {
    des: 'Индикаторная торговая стратегия использует в своей работе индикаторы. Принцип работы по индикаторной стратегии сводится к тому, чтобы получать сигналы от используемых на графике цены индикаторов, с целью определения наиболее лучших условий вхождения в рынок. Индикаторы используются как встроенные в терминал, так и разработанные лично Булатом. На текущий момент это наиболее популярная торговая стратегия среди трейдеров.',
    fullDes: `
      <p class='text-xl'>1 неделя.Технический Анализ от А до Я</p>
      <p>- Знакомство с аналитической платформой TRADINGVIEW</p>
      <p>- Что такое трейдинг по техническому анализу</p>
      <p>- Обучение техническому анализу графика криптовалюты</p>
      <p>- Изучение базовых составляющих графика: уровни, фигуры, индикаторы</p>
      <p>- Эксклюзивные лайвхаки от Главы Аналитического отдела Rsi Capital </p>
      <br/><br/>
      
      <p class='text-xl'>2 неделя.Технический анализ</p>
      <p>- Анализ объемов</p>
      <p>- Торговля по уровням</p>
      <p>- Торговля на пробои</p>
      <p>- Лучшие точки входа и выхода</p>
      <p>- Глобальный анализ и способ сбора информации</p>
      <p>- Авторская методика анализа от Булата Саттарова</p>
      <br/><br/>
      
      <p class='text-xl'>3 неделя.Торговля</p>
      <p>- Что такое фьючерсная и спотовая торговля криптовалютой</p>
      <p>- Мани менеджмент, риск менеджмент и как с минимальным риском получать больший профит</p>
      <p>- Сбор информации и поиск прибыльных монет с помощью Эксклюзивного автоматизированного бота</p>
      <br/><br/>
      
      <p class='text-xl'>4 неделя.Торговля</p>
      <p>- Особенности торговли альткоинами, торговли с плечом, торговли в Лонг и Шорт</p>
      <p>- Бинанс, обзор платформы, как открывать и закрывать сделки на платформе</p>
      <p>- Совместная торговля с Булатом Саттаровым</p>
    `,
  },
  2: {
    des: 'Трейдинг  –  это не игра на бирже, деньги и везение. Трейдинг – это дисциплина и базовые знания по экономике, математике, финансовым рынкам и техническому анализу.',
    fullDes: `
      <img src='${bulatImg}' class='mb-4' />
    
      <p>
        Трейдинг  –  это не игра на бирже, деньги и везение. Трейдинг – это дисциплина и базовые знания по экономике, математике, финансовым рынкам и техническому анализу.
        <br/><br/>
        Курс Индикаторная торговля 3.0 - способ освоить навык и разобраться как устроен рынок индикаторной торговли и научиться совершать первые сделки на торговой платформе BINANCE и на фондовом рынке.
        Обучение включает в себя 4 недели, в течение которых вы выстроите торговую стратегию, узнаете, как контролировать риски и совершать обдуманные сделки.
      </p>
      
      <p>
        ПРЕПОДАВАТЕЛЬ
        <br/>
        Булат Саттаров:
        <br/>
        •  действующий трейдер (на рынке с 2016 года)
        <br/>
        •  управляющий фонда RSI Capital
        <br/>
        •  автор собственной методики торговли
        <br/>
        •  автор книги «Трейдинг для начинающих»
        <br/>
        •  создатель работающих индикаторов, доступных исключительно для учеников курса
        <br/>
        •  Автор закрытого телеграмм-канала с аналитикой и разборов проектов RSI CLUB
        <br/><br/>
        <b>КОМУ ПОДОЙДЕТ КУРС</b>
        <br/>
        • Начинающим трейдерам<br/>
        Научитесь анализировать финансовую информацию и торговать на основе данных, а не интуиции. Разработаете собственную торговую стратегию и контролировать риски.
        <br/>
        • Тем, кто хочет перейти от инвестирования к трейдингу<br/>
        Получите структурированные знания эксперта-трейдера. Попрактикуетесь на готовых кейсах. Поймёте, какой стиль работы на рынке подходит вам.
        <br/>
        <br/>
        <b>ЧТО ВЫ ПОЛУЧИТЕ</b>
        <br/>
        Ознакомитесь с принципами построения торговой системы как для трейдинга, так и индикаторной торговли.
        <br/>
        Сформируете понимание риск- и мани-менеджментов.
        <br/>
        Узнаете, что такое фьючерсная и спотовая торговля криптовалютой.
        <br/>
        Получите доступ к автоматизированному боту по сбору информации и поиску точек входа в сделку.
        <br/>
        Возможность окупить курс уже на 4 неделе обучения.
        <br/>
        Личная поддержка, ответы на вопросы и лайфхаки Булата Саттарова и кураторов курса.
        <br/>
        <br/>
        <b>КАК ПРОХОДИТ ОБУЧЕНИЕ</b>
        <br/>
        Обучение проходит дистанционно - 4 недели. Занятия с преподавателем проходят 3 раза в неделю в течение часа-двух. 
        <br/>
        Теоретические материалы доступны в личном кабинете на протяжении курса.
        <br/>
        ИНДИКАТОРНАЯ ТОРГОВЛЯ 3.0 - надежный способ получить набор знаний для эффективного старта на финансовом рынке и формировании стратегии торговли . Вы получаете знания о том, как анализировать цены, правильно принимать торговые решения и распределять свой капитал на основании технических данных.
        <br/>
        Старт обучения: 28 февраля 2022 года
        <br/>
        <br/>
        Количество мест: 20<br/>
        Стоимость курса: 2000$<br/>
      </p>
    `,
  },
  3: {
    des: 'Трейдинг  –  это не игра на бирже, деньги и везение. Трейдинг – это дисциплина и базовые знания по экономике, математике, финансовым рынкам и техническому анализу.',
    fullDes: `
      <img src='${bulatImg}' class='mb-4' />
    
      <p>
        Трейдинг  –  это не игра на бирже, деньги и везение. Трейдинг – это дисциплина и базовые знания по экономике, математике, финансовым рынкам и техническому анализу.
        <br/><br/>
        Курс Индикаторная торговля 3.0 - способ освоить навык и разобраться как устроен рынок индикаторной торговли и научиться совершать первые сделки на торговой платформе BINANCE и на фондовом рынке.
        Обучение включает в себя 4 недели, в течение которых вы выстроите торговую стратегию, узнаете, как контролировать риски и совершать обдуманные сделки.
      </p>
      
      <p>
        ПРЕПОДАВАТЕЛЬ
        <br/>
        Булат Саттаров:
        <br/>
        •  действующий трейдер (на рынке с 2016 года)
        <br/>
        •  управляющий фонда RSI Capital
        <br/>
        •  автор собственной методики торговли
        <br/>
        •  автор книги «Трейдинг для начинающих»
        <br/>
        •  создатель работающих индикаторов, доступных исключительно для учеников курса
        <br/>
        •  Автор закрытого телеграмм-канала с аналитикой и разборов проектов RSI CLUB
        <br/><br/>
        <b>КОМУ ПОДОЙДЕТ КУРС</b>
        <br/>
        • Начинающим трейдерам<br/>
        Научитесь анализировать финансовую информацию и торговать на основе данных, а не интуиции. Разработаете собственную торговую стратегию и контролировать риски.
        <br/>
        • Тем, кто хочет перейти от инвестирования к трейдингу<br/>
        Получите структурированные знания эксперта-трейдера. Попрактикуетесь на готовых кейсах. Поймёте, какой стиль работы на рынке подходит вам.
        <br/>
        <br/>
        <b>ЧТО ВЫ ПОЛУЧИТЕ</b>
        <br/>
        Ознакомитесь с принципами построения торговой системы как для трейдинга, так и индикаторной торговли.
        <br/>
        Сформируете понимание риск- и мани-менеджментов.
        <br/>
        Узнаете, что такое фьючерсная и спотовая торговля криптовалютой.
        <br/>
        Получите доступ к автоматизированному боту по сбору информации и поиску точек входа в сделку.
        <br/>
        Возможность окупить курс уже на 4 неделе обучения.
        <br/>
        Личная поддержка, ответы на вопросы и лайфхаки Булата Саттарова и кураторов курса.
        <br/>
        <br/>
        <b>КАК ПРОХОДИТ ОБУЧЕНИЕ</b>
        <br/>
        Обучение проходит дистанционно - 4 недели. Занятия с преподавателем проходят 3 раза в неделю в течение часа-двух. 
        <br/>
        Теоретические материалы доступны в личном кабинете на протяжении курса.
        <br/>
        ИНДИКАТОРНАЯ ТОРГОВЛЯ 3.0 - надежный способ получить набор знаний для эффективного старта на финансовом рынке и формировании стратегии торговли . Вы получаете знания о том, как анализировать цены, правильно принимать торговые решения и распределять свой капитал на основании технических данных.
        <br/>
        Старт обучения: 28 февраля 2022 года
        <br/>
        <br/>
        Количество мест: 20<br/>
        Стоимость курса: 2000$<br/>
      </p>
    `,
  },
  4: {
    des: 'Этот курс поможет вам освоить новую профессию и откроет для вас дополнительный источник дохода.',
    fullDes: `
      <img src='${newTool}' class='mb-4' />
    
      <p>
        В век высоких технологий, децентрализованных финансов, онлайн-банков и электронных кошельков жизненно необходимо разбираться в альтернативных финансах. 
        Одним из которых является криптовалюта.
      </p>
      <br/>
      <p>
      Сегодня криптовалюты это не только инструмент инвестиций и технологий, но так же обмена, хранения и сбережения.<br/><br/>

      - Где безопасно хранить свои средства, к которым доступ будет только у вас?<br/>
      - Как путешествовать по миру и не волноваться как и где обменять валюту?<br/>
      - Как зарабатывать из любой точки мира, занимаясь сделками купли-продажи Крипты пряма с мобильного телефона?<br/>
      - Где выгодно покупать валюту, не оплачивая комиссии банков бирж и прочих контрагентов?<br/>
      - Как не терять на разнице валютных курсов?<br/>
     И наконец,<br/>
      -  Как открыть собственный бизнес в финансовой сфере с доходом более 7000 $ уже через месяц?
      <br/><br/>
     
     На все эти вопросы вы найдете ответ на курсе «Криптовалюта - новый финансовый инструмент!» <br/><br/>
     
     Стань профессионалом в одной из самых восстребованных областей и научись безрисково зарабатывать от 20 до 200% в месяц!<br/><br/>
     
     Программа курса:<br/><br/>
     
      - Введение в курс (организация курса, группы, принцип работы)<br/><br/>
      - Что такое криптовалюта, виды крипто-кошельков, как работают переводы,<br/>
      - Безопасность! Централизованные и децентрализованные кошельки. Сид фразы, холодные и горячие кошельки. Криптовалютные ноды и сети блокчейн<br/>
      - Установка и регистрация криптокошельков и аккаунтах на биржах.<br/>
      - Основные понятия и термины. Логика заработка. Почему это востребовано?<br/>
      - Как зарабатывать на р2р обмене , виды р2р площадок, виды ордеров, прием и отправка денег на карты<br/>
      - Хэджирование рисков, как избежать рисков из-за изменения курсов<br/>
      - ОТС сделки (Работа за наличные с крупнейшими поставщиками ликвидности)<br/>
      - Актуальные  международные финансовые услуги с криптовалютой и как на этом заработать, не имея собственного капитала<br/>
      - Партнерство с сервисом RSI GARANT - используй опыт, ликвидность и все возможности команды, заработавшей более 1 млн долларов за 2021 год на обмене криптоактивов<br/>
      - Реферальные программы и партнерские условия с нашим сервисом, с возможностью запустить свой собственный офис<br/>
      - Руководство по открытию офиса, постановка работы, набор команды.
      </p>
    `,
  },
  5: {
    des: 'Этот курс поможет вам освоить новую профессию и откроет для вас дополнительный источник дохода.',
    fullDes: `
      <img src='${newTool}' class='mb-4' />
    
      <p>
        В век высоких технологий, децентрализованных финансов, онлайн-банков и электронных кошельков жизненно необходимо разбираться в альтернативных финансах. 
        Одним из которых является криптовалюта.
      </p>
      <br/>
      <p>
      Сегодня криптовалюты это не только инструмент инвестиций и технологий, но так же обмена, хранения и сбережения.<br/><br/>

      - Где безопасно хранить свои средства, к которым доступ будет только у вас?<br/>
      - Как путешествовать по миру и не волноваться как и где обменять валюту?<br/>
      - Как зарабатывать из любой точки мира, занимаясь сделками купли-продажи Крипты пряма с мобильного телефона?<br/>
      - Где выгодно покупать валюту, не оплачивая комиссии банков бирж и прочих контрагентов?<br/>
      - Как не терять на разнице валютных курсов?<br/>
     И наконец,<br/>
      -  Как открыть собственный бизнес в финансовой сфере с доходом более 7000 $ уже через месяц?
      <br/><br/>
     
     На все эти вопросы вы найдете ответ на курсе «Криптовалюта - новый финансовый инструмент!» <br/><br/>
     
     Стань профессионалом в одной из самых восстребованных областей и научись безрисково зарабатывать от 20 до 200% в месяц!<br/><br/>
     
     Программа курса:<br/><br/>
     
      - Введение в курс (организация курса, группы, принцип работы)<br/><br/>
      - Что такое криптовалюта, виды крипто-кошельков, как работают переводы,<br/>
      - Безопасность! Централизованные и децентрализованные кошельки. Сид фразы, холодные и горячие кошельки. Криптовалютные ноды и сети блокчейн<br/>
      - Установка и регистрация криптокошельков и аккаунтах на биржах.<br/>
      - Основные понятия и термины. Логика заработка. Почему это востребовано?<br/>
      - Как зарабатывать на р2р обмене , виды р2р площадок, виды ордеров, прием и отправка денег на карты<br/>
      - Хэджирование рисков, как избежать рисков из-за изменения курсов<br/>
      - ОТС сделки (Работа за наличные с крупнейшими поставщиками ликвидности)<br/>
      - Актуальные  международные финансовые услуги с криптовалютой и как на этом заработать, не имея собственного капитала<br/>
      - Партнерство с сервисом RSI GARANT - используй опыт, ликвидность и все возможности команды, заработавшей более 1 млн долларов за 2021 год на обмене криптоактивов<br/>
      - Реферальные программы и партнерские условия с нашим сервисом, с возможностью запустить свой собственный офис<br/>
      - Руководство по открытию офиса, постановка работы, набор команды.
      </p>
    `,
  },
};
