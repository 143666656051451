/* eslint-disable indent */
import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import { apiGet, apiPost } from '../../../../api';
import { getActiveClass } from '../../../../helpers/className';
import useDebounce from '../../../../hooks/useDebounce';
import { useFetch } from '../../../../hooks/useFetch';
import useUrl from '../../../../hooks/useUrl';
import { updateWalletCount } from '../../../../store/user/actions';
import { Badge } from '../../../common/badge';
import { BillBlock } from '../../../common/billBlock';
import { Icon } from '../../../common/icon';
import { NotifyBlock } from '../../../common/notify';
import { Pagination as PaginationData } from '../../../common/pagination/index';
import Spinner from '../../../common/spinner';
import { Button } from '../../../common/ui/button';
import { Input } from '../../../common/ui/input';
import { Modal } from '../../../common/ui/modal';
import { WalletBLock } from '../../../common/wallet';
import './index.scss';

SwiperCore.use([Pagination, Navigation]);

const walletMoney = ['USDR', 'BTC','USDT (TRC20)', 'USDT', 'ETH'];

export const WalletPage = () => {
  const { register, setValue, watch } = useForm();
  const dispatch = useDispatch();

  const { page } = useUrl();

  const {
    response,
    loading,
    fetchData: refreshWalletList,
  } = useFetch({ url: '/address', defaultValue: [] });

  const usdrBillList = [
    {
      name: 'USDT',
      value: response?.data?.[1].balance,
    },
    {
      name: 'USDT (TRC20)',
      value: response?.data?.[2].balance,
    },
    {
      name: 'BTC',
      value: response?.data?.[3].balance,
    },
    {
      name: 'ETH',
      value: response?.data?.[0].balance,
    },
   
  ];

  const [userInfoById, setUserInfoById] = useState(null);
  const [selectedWallet, setSelectedWallet] = useState('USDR');
  const [investWallet, setInvestWallet] = useState(null);
  const [historyList, setHistoryList] = useState([]);
  const [errObj, setErrObj] = useState({});
  const [comissions, setComissions] = useState({});
  const [commissionSendUsdt, setCommissionSendUsdt] = useState({});
  const [commissionSendUsdtTRC20, setCommissionSendUsdtTRC20] = useState({});
  const [commissionSendEth, setCommissionSendEth] = useState({});
  const [commissionSendBtc, setCommissionSendBtc] = useState({});
  const [loaderSend, setLoaderSend] = useState(false);

  const [validateUsdrSend, setValidateUsdrSend] = useState({
    email: false,
    sms: false,
  });

  const [validateSendWallet, setValidateSendWallet] = useState({
    email: false,
    sms: false,
  });

  const [visible, setVisible] = useState({
    USDRIn: false,
    USDROut: false,
    USDRSend: false,
    USDTIn: false,
    USDTSend: false,
    USDTTRC20In: false,
    USDTTRC20Send: false,
    ETHIn: false,
    ETHSend: false,
    BTCIn: false,
    BTCSend: false,
  });

  const [alertView, setAlertView] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [textColor, setTextcolor] = useState('#fff');

  const [loadingAddress, setLoadingAddress] = useState(false);

  const handleAlertOpen = (text, textColor) => {
    setAlertView(true);
    setAlertText(text);
    setTextcolor(textColor);
  };
  const handleAlertClose = () => {
    setAlertView(false);
    setAlertText('');
  };

  const handleChangeBillBlock = value => {
    setInvestWallet(value);
  };

  const toggleModal = wallet => {
    const value = { ...visible };
    value[wallet] = !value[wallet];

    setVisible(value);
    setInvestWallet(null);
  };

  const getAddressHistory = () => {
    setLoadingAddress(true);
    apiGet({
      url: `/address/history?name=${selectedWallet}&page=${page || 1}&limit=10`,
    }).then(res => {
      setLoadingAddress(false);
      if (res.error) {
        console.error('Авторизации');
        return;
      }

      setHistoryList(res.data);
    });
  };

  const usdrToUpFromWallet = () => {
    let sum = 0;
    if (investWallet === 'BTC') {
      sum = watch('usdrInBtc');
    }
    if (investWallet === 'ETH') {
      sum = watch('usdrInEth');
    }
    if (investWallet === 'USDT') {
      sum = watch('usdrInUsdt');
    }    
    if (investWallet === 'USDT (TRC20)') {
      sum = watch('usdrInUsdtTRC20');
    }

    apiPost({
      url: `/address/replenish?name=${investWallet}`,
      postData: { amount: sum },
    }).then(res => {
      if (res) {
        if (res.error) {
          setErrObj(res.data);
          return;
        }
        if (!res.error) {
          handleAlertOpen('Пополнение успешно выполнено', 'black');
          toggleModal('USDRIn');
          refreshWalletList();
          getAddressHistory();
        }
      }
    }, e => console.log(e));
  };

  const usdrOutputWallet = () => {
    setLoaderSend(true);
    let sum = 0;
    if (investWallet === 'BTC') {
      sum = watch('usdrInBtcWithCommission');
    }
    if (investWallet === 'ETH') {
      sum = watch('usdrInEthWithCommission');
    }
    if (investWallet === 'USDT') {
      sum = watch('usdrInUsdtWithCommission');
    }
    if (investWallet === 'USDT (TRC20)') {
      sum = watch('usdrInUsdtTRC20WithCommission');
    }
    

    apiPost({
      url: `/address/payout?name=${investWallet}`,
      postData: { amount: sum },
    })
      .then(res => {
        if (res) {
          if (res.error) {
            setErrObj(res.data);
            return;
          }
          if (!res.error) {
            handleAlertOpen('Вывод выполнен успешно выполнено', 'black');
            toggleModal('USDROut');
            refreshWalletList();
            getAddressHistory();
          }
        }
      }, e => console.log(e))
      .then(() => setLoaderSend(false));
  };

  //конвертирование валюты без учета комиссии

  const handleUsdrInEthChange = ({ target: { value } }) => {
    setValue(
      'usdrInEth',
      comissions[investWallet]['REPLENISH_RATE'].value * value
    );
  };

  const handleUsdrOutEthChange = ({ target: { value } }) => {
    setValue(
      'usdrETH',
      value / comissions[investWallet]['REPLENISH_RATE'].value
    );
  };

  const handleUsdrInUsdtChange = ({ target: { value } }) => {
    setValue(
      'usdrInUsdt',
      value * comissions[investWallet]['REPLENISH_RATE'].value
    );
  };

  const handleUsdrOutUsdtChange = ({ target: { value } }) => {
    setValue(
      'usdrUSDT',
      value / comissions[investWallet]['REPLENISH_RATE'].value
    );
  };

  const handleUsdrInUsdtTRC20Change = ({ target: { value } }) => {
    setValue(
      'usdrInUsdtTRC20',
      value * comissions[investWallet]['REPLENISH_RATE'].value
    );
  };

  const handleUsdrOutUsdtTRC20Change = ({ target: { value } }) => {
    setValue(
      'usdrUSDTTRC20',
      value / comissions[investWallet]['REPLENISH_RATE'].value
    );
  };

  const handleUsdrInBtcChange = ({ target: { value } }) => {
    setValue(
      'usdrInBtc',
      comissions[investWallet]['REPLENISH_RATE'].value * value
    );
  };

  const handleUsdrOutBtcChange = ({ target: { value } }) => {
    setValue(
      'usdrBTC',
      value / comissions[investWallet]['REPLENISH_RATE'].value
    );
  };

  // C учетом комиссии

  const handleUsdrInUsdtChangeWithComission = ({ target: { value } }) => {
    let source =
      (value * comissions[investWallet]['PAYOUT_RATE'].value) /
      ((100 - comissions[investWallet]['PAYOUT_COMMISSION'].value) / 100);
    let commiss =
      (source * comissions[investWallet]['PAYOUT_COMMISSION'].value) / 100;

    if (commiss < comissions[investWallet]['PAYOUT_MIN_COMMISSION'].value) {
      source =
        value * comissions[investWallet]['PAYOUT_RATE'].value +
        comissions[investWallet]['PAYOUT_MIN_COMMISSION'].value;
    }
    setValue('UsdrOutUsdt', `${Math.abs(+value - source)}USDR`);

    if (source < 0 || value < 0) {
      return;
    }

    setValue('usdrInUsdtWithCommission', source);
  };

  const handleUsdrOutUsdtChangeWithComission = ({ target: { value } }) => {
    let commiss =
      (value * comissions[investWallet]['PAYOUT_COMMISSION'].value) / 100;
    setValue('UsdrOutUsdt', `${commiss}USDR`);
    if (commiss < comissions[investWallet]['PAYOUT_MIN_COMMISSION'].value) {
      commiss = comissions[investWallet]['PAYOUT_MIN_COMMISSION'].value;
      setValue('UsdrOutUsdt', `${commiss}USDR`);
    }

    const res =
      (value - commiss) / comissions[investWallet]['PAYOUT_RATE'].value;

    if (res < 0 || value < 0) {
      return;
    }

    setValue('usdrUSDTWithCommission', res);
  };

  const handleUsdrInUsdtTRC20ChangeWithComission = ({ target: { value } }) => {
    let source =
      (value * comissions[investWallet]['PAYOUT_RATE'].value) /
      ((100 - comissions[investWallet]['PAYOUT_COMMISSION'].value) / 100);
    let commiss =
      (source * comissions[investWallet]['PAYOUT_COMMISSION'].value) / 100;

    if (commiss < comissions[investWallet]['PAYOUT_MIN_COMMISSION'].value) {
      source =
        value * comissions[investWallet]['PAYOUT_RATE'].value +
        comissions[investWallet]['PAYOUT_MIN_COMMISSION'].value;
    }
    setValue('UsdrOutUsdtTRC20', `${Math.abs(+value - source)}USDR`);

    if (source < 0 || value < 0) {
      return;
    }

    setValue('usdrInUsdtTRC20WithCommission', source);
  };

  const handleUsdrOutUsdtTRC20ChangeWithComission = ({ target: { value } }) => {
    let commiss =
      (value * comissions[investWallet]['PAYOUT_COMMISSION'].value) / 100;
    setValue('UsdrOutUsdtTRC20', `${commiss}USDR`);
    if (commiss < comissions[investWallet]['PAYOUT_MIN_COMMISSION'].value) {
      commiss = comissions[investWallet]['PAYOUT_MIN_COMMISSION'].value;
      setValue('UsdrOutUsdtTRC20', `${commiss}USDR`);
    }

    const res =
      (value - commiss) / comissions[investWallet]['PAYOUT_RATE'].value;

    if (res < 0 || value < 0) {
      return;
    }

    setValue('usdrUSDTTRC20WithCommission', res);
  };

  const handleUsdrInEthChangeWithComission = ({ target: { value } }) => {
    let source =
      (value * comissions[investWallet]['PAYOUT_RATE'].value) /
      ((100 - comissions[investWallet]['PAYOUT_COMMISSION'].value) / 100);
    let commiss =
      (source * comissions[investWallet]['PAYOUT_COMMISSION'].value) / 100;
    setValue('UsdrOutEth', `${commiss}USDR`);
    if (commiss < comissions[investWallet]['PAYOUT_MIN_COMMISSION'].value) {
      source =
        value * comissions[investWallet]['PAYOUT_RATE'].value +
        comissions[investWallet]['PAYOUT_MIN_COMMISSION'].value;
      setValue(
        'UsdrOutEth',
        `${comissions[investWallet]['PAYOUT_MIN_COMMISSION'].value}USDR`
      );
    }

    if (source < 0 || value < 0) {
      return;
    }

    setValue('usdrInEthWithCommission', source);
  };

  const handleUsdrOutEthChangeWithComission = ({ target: { value } }) => {
    let commiss =
      (value * comissions[investWallet]['PAYOUT_COMMISSION'].value) / 100;
    setValue('UsdrOutEth', `${commiss}USDR`);
    if (commiss < comissions[investWallet]['PAYOUT_MIN_COMMISSION'].value) {
      commiss = comissions[investWallet]['PAYOUT_MIN_COMMISSION'].value;
      setValue('UsdrOutEth', `${commiss}USDR`);
    }

    const res =
      (value - commiss) / comissions[investWallet]['PAYOUT_RATE'].value;

    if (res < 0 || value < 0) {
      return;
    }

    setValue('usdrEthWithCommission', res);
  };

  const handleUsdrInBtcChangeWithComission = ({ target: { value } }) => {
    let source =
      (value * comissions[investWallet]['PAYOUT_RATE'].value) /
      ((100 - comissions[investWallet]['PAYOUT_COMMISSION'].value) / 100);
    let commiss =
      (source * comissions[investWallet]['PAYOUT_COMMISSION'].value) / 100;
    setValue('UsdrOutBtc', `${commiss}USDR`);
    if (commiss < comissions[investWallet]['PAYOUT_MIN_COMMISSION'].value) {
      source =
        value * comissions[investWallet]['PAYOUT_RATE'].value +
        comissions[investWallet]['PAYOUT_MIN_COMMISSION'].value;
      setValue(
        'UsdrOutBtc',
        `${comissions[investWallet]['PAYOUT_MIN_COMMISSION'].value}USDR`
      );
    }

    if (source < 0 || value < 0) {
      return;
    }

    setValue('usdrInBtcWithCommission', source);
  };

  const handleUsdrOutBtcChangeWithComission = ({ target: { value } }) => {
    let commiss =
      (value * comissions[investWallet]['PAYOUT_COMMISSION'].value) / 100;
    setValue('UsdrOutBtc', `${commiss}USDR`);
    if (commiss < comissions[investWallet]['PAYOUT_MIN_COMMISSION'].value) {
      commiss = comissions[investWallet]['PAYOUT_MIN_COMMISSION'].value;
      setValue('UsdrOutBtc', `${commiss}USDR`);
    }

    const res =
      (value - commiss) / comissions[investWallet]['PAYOUT_RATE'].value;

    if (res < 0 || value < 0) {
      return;
    }

    setValue('usdrBtcWithCommission', res);
  };

  const getBalanceWallet = () => {
    apiGet({
      url: '/address',
    }).then(res => {
      if (res.error) {
        console.error('Получение кошелька');
        return;
      }
      dispatch(
        updateWalletCount(+res.data.filter(i => i.name === 'USDR')[0]?.balance)
      );
    });
  };

  //

  const usdrSendUsdrWallet = () => {
    const recipient_id = watch('userIdUsdr');
    const sum = watch('usdrOutUsdr');

    let formData = new FormData();

    if (recipient_id) {
      formData.append('recipient_id', recipient_id);
    }

    if (sum) {
      formData.append('sum', sum);
    }

    if (validateUsdrSend.email) {
      formData.append('emailCode', watch('emailCodeUsdr'));
    }

    if (validateUsdrSend.sms) {
      formData.append('smsCode', watch('smsCodeUsdr'));
    }

    setLoaderSend(true);

    apiPost({
      url: '/balance/send',
      postData: formData,
    })
      .then(res => {
        if (res) {
          if (res.error) {
            setErrObj(res.data);
            return;
          }

          if (!res.error) {
            if (res.data === true) {
              getBalanceWallet();
              handleAlertOpen('Перевод выполнен успешно', 'black');
              toggleModal('USDRSend');
              refreshWalletList();
              getAddressHistory();
              setValue('userIdUsdr', '');
              setValue('usdrOutUsdr', '');
              setValue('smsCodeUsdr', '');
              setValue('emailCodeUsdr', '');
              setValidateUsdrSend({ sms: false, email: false });
            } else {
              setValidateUsdrSend({
                sms: res.data.smsCode,
                email: res.data.emailCode,
              });
            }
          }
        }
      }, e => console.log(e))
      .then(() => setLoaderSend(false));
  };

  const sendWalletBalance = () => {
    setLoaderSend(true);
    let amount = 0;
    let to = '';
    if (selectedWallet === 'BTC') {
      amount = watch('btcOut');
      to = watch('userIdBtc');
    }
    if (selectedWallet === 'ETH') {
      amount = watch('ethOut');
      to = watch('userIdEth');
    }
    if (selectedWallet === 'USDT') {
      amount = watch('usdtOut');
      to = watch('userIdUsdt');
    }

    if (selectedWallet === 'USDT (TRC20)') {
      amount = watch('usdtTRC20Out');
      to = watch('userIdUsdtTRC20');
    }

    let formData = new FormData();

    if (amount !== 0) {
      formData.append('amount', amount);
    }

    if (to) {
      formData.append('to', to);
    }

    if (validateSendWallet.email) {
      formData.append('emailCode', watch('emailCodeValidate'));
    }

    if (validateSendWallet.sms) {
      formData.append('smsCode', watch('smsCodeValidate'));
    }
    apiPost({
      url: `/address/send?name=${selectedWallet}`,
      postData: formData,
    })
      .then(res => {
        console.log('до',res);
        if (res) {
          if (res.error) {
            setErrObj(res.data);
            return;
          }
          if (!res.error) {
            setErrObj({});
            if (res.data === true) {
              handleAlertOpen('Перевод выполнен успешно', 'black');
              if (selectedWallet === 'USDT') {
                toggleModal('USDTSend');
                setValue('usdtOutWithComission', '');
                setValue('usdtOut', '');
                setValue('userIdUsdt', '');
                setCommissionSendUsdt({});
              }

              if (selectedWallet === 'USDT (TRC20)') {
                toggleModal('USDTTRC20Send');
                setValue('usdtTRC20OutWithComission', '');
                setValue('usdtTRC20Out', '');
                setValue('userIdUsdtTRC20', '');
                setCommissionSendUsdtTRC20({});
              }

              if (selectedWallet === 'BTC') {
                toggleModal('BTCSend');
                setValue('btcOutWithComission', '');
                setValue('btcOut', '');
                setValue('userIdBtc', '');
                setCommissionSendBtc({});
              }

              if (selectedWallet === 'ETH') {
                toggleModal('ETHSend');
                setValue('ethOutWithComission', '');
                setValue('ethOut', '');
                setValue('userIdEth', '');
                setCommissionSendEth({});
              }
              setValue('emailCodeValidate', '');
              setValue('smsCodeValidate', '');
              refreshWalletList();
              getAddressHistory();
            } else {
              setValidateSendWallet({
                email: res.data.emailCode,
                sms: res.data.smsCode,
              });
            }
          }
        }
      }, () => console.log('skl'))
      .then(() => setLoaderSend(false));
  };

  const getUsdtTRC20Comission = val => {
    apiGet({
      url: `/commissions?to=${val}`,
    }).then(res => {
      if (res.error) {
        console.error('Получение коммисий у кошелька');
        return;
      }
      if (!res.error) {
        setCommissionSendUsdtTRC20(res.data);
      }
    });
  };

  const handleUsdtTRC20Send = ({ target: { value } }) => {
    let num =
      +value +
      commissionSendUsdtTRC20[selectedWallet]?.['TRANSACTION_COMMISSION'].value;
    setValue('usdtTRC20OutWithComission', num);
  };

  const handleUsdtTRC20SendWithComission = ({ target: { value } }) => {
    let num =
      +value -
      commissionSendUsdtTRC20[selectedWallet]?.['TRANSACTION_COMMISSION'].value;

    if (num < 0) {
      num = 0;
    }
    setValue('usdtTRC20Out', num);
  };

  const getUsdtComission = val => {
    apiGet({
      url: `/commissions?to=${val}`,
    }).then(res => {
      if (res.error) {
        console.error('Получение коммисий у кошелька');
        return;
      }
      if (!res.error) {
        setCommissionSendUsdt(res.data);
      }
    });
  };

  const handleUsdtSend = ({ target: { value } }) => {
    let num =
      +value +
      commissionSendUsdt[selectedWallet]?.['TRANSACTION_COMMISSION'].value;
    setValue('usdtOutWithComission', num);
  };

  const handleUsdtSendWithComission = ({ target: { value } }) => {
    let num =
      +value -
      commissionSendUsdt[selectedWallet]?.['TRANSACTION_COMMISSION'].value;

    if (num < 0) {
      num = 0;
    }
    setValue('usdtOut', num);
  };

  const handleUsdtIdUpdate = ({ target: { value } }) => {
    if (value.length >= 15) {
      getComissions();
      getUsdtComission(value);
    } else {
      setCommissionSendUsdt({});
    }
  };

  const handleUsdtTRC20IdUpdate = ({ target: { value } }) => {
    if (value.length >= 15) {
      getComissions();
      getUsdtTRC20Comission(value);
    } else {
      setCommissionSendUsdtTRC20({});
    }
  };

  const handleEthIdUpdate = ({ target: { value } }) => {
    if (value.length >= 20) {
      apiGet({
        url: `/commissions?to=${value}`,
      }).then(res => {
        if (res.error) {
          console.error('Получение коммисий у кошелька');
          return;
        }
        if (!res.error) {
          setCommissionSendEth(res.data);
        }
      });
    } else {
      setCommissionSendEth({});
    }
  };

  const handleEthSend = ({ target: { value } }) => {
    let num =
      +value +
      commissionSendEth[selectedWallet]?.['TRANSACTION_COMMISSION'].value;
    setValue('ethOutWithComission', num);
  };

  const handleEthSendWithComission = ({ target: { value } }) => {
    let num =
      +value -
      commissionSendEth[selectedWallet]?.['TRANSACTION_COMMISSION'].value;

    if (num < 0) {
      num = 0;
    }
    setValue('ethOut', num);
  };

  const handleBtcIdUpdate = ({ target: { value } }) => {
    if (value.length >= 20) {
      apiGet({
        url: `/commissions?to=${value}`,
      }).then(res => {
        if (res.error) {
          console.error('Получение коммисий у кошелька');
          return;
        }
        if (!res.error) {
          setCommissionSendBtc(res.data);
        }
      });
    } else {
      setCommissionSendBtc({});
    }
  };

  const handleBtcSend = ({ target: { value } }) => {
    let num =
      +value +
      commissionSendBtc[selectedWallet]?.['TRANSACTION_COMMISSION'].value;

    setValue('btcOutWithComission', num);
  };

  const handleBtcSendWithComission = ({ target: { value } }) => {
    let num =
      +value -
      commissionSendBtc[selectedWallet]?.['TRANSACTION_COMMISSION'].value;

    if (num < 0) {
      num = 0;
    }

    setValue('btcOut', num);
  };

  const getComissions = () => {
    apiGet({
      url: '/commissions?',
    }).then(res => {
      if (res.error) {
        console.error('Получение коммисий');
        return;
      }

      setComissions(res.data);
    });
  };

  const handleChangeWallet = value => {
    setSelectedWallet(value);
  };

  useEffect(() => {
    getAddressHistory();
  }, [selectedWallet, page]);

  useEffect(() => {
    getAddressHistory();
    getComissions();
  }, []);

  useEffect(() => {
    setErrObj({});
  }, [investWallet]);

  useEffect(() => {
    setErrObj({});
    setValue('ethOutWithComission', '');
    setValue('ethOut', '');
    setValue('userIdEth', '');

    setValue('btcOutWithComission', '');
    setValue('btcOut', '');
    setValue('userIdBtc', '');

    setValue('usdtOutWithComission', '');
    setValue('usdtOut', '');
    setValue('userIdUsdt', '');

    setValue('usdtTRC20OutWithComission', '');
    setValue('usdtTRC20Out', '');
    setValue('userIdUsdtTRC20', '');

    setCommissionSendBtc({});
    setCommissionSendEth({});
    setCommissionSendUsdt({});
    setCommissionSendUsdtTRC20({});
  }, [selectedWallet]);

  const funcPaginationSwiperChange = index => {
    setSelectedWallet(walletMoney[index]);
  };

  const renderBillBlock = ({ name, value }) => {
    if (name !== 'BTC') {
      return (
        <Col key={name} sm={4}>
          <BillBlock
            name={name}
            value={value}
            activeClass={getActiveClass(name, investWallet)}
            onClick={handleChangeBillBlock}
          />
        </Col>
      );
    }
  };

  const renderBillBlock2 = ({ name, value }) => {
    return (
      <Col key={name} sm={4}>
        <BillBlock
          name={name}
          value={value}
          activeClass={getActiveClass(name, investWallet)}
          onClick={handleChangeBillBlock}
        />
      </Col>
    );
  };
  const renderWallet = item => {
    const onClickIn = () =>  item.name !== 'USDT (TRC20)' ? toggleModal(`${item.name}In`) : toggleModal('USDTTRC20In') ;
    const onClickSend = () => item.name !== 'USDT (TRC20)' ? toggleModal(`${item.name}Send`) : toggleModal('USDTTRC20Send');
    const onClickOut = () =>  item.name !== 'USDT (TRC20)' ? toggleModal(`${item.name}Out`) : toggleModal('USDTTRC20Out');

  
    return (
      <SwiperSlide key={item.name}>
        <WalletBLock
          name={item.name}
          value={item.balance}
          bill={item.address}
          onClickIn={onClickIn}
          onClickSend={onClickSend}
          onClickOut={onClickOut}
          activeClass={getActiveClass(item.name, selectedWallet)}
          onClick={handleChangeWallet}
          copyAddress={() => handleAlertOpen('Счет успешно скопирован')}
        />
      </SwiperSlide>
    );
  };

  const getUserInfoById = async id => {
    const res = await apiGet({ url: `/user/user-info-by-id?id=${id}` });

    if (!res.error) {
      setUserInfoById(res.data);
    }
  };

  const throttledUserId = useDebounce(watch('userIdUsdr'), 300);

  useEffect(() => {
    getUserInfoById(throttledUserId);
  }, [throttledUserId]);

  return (
    <>
      <Container fluid>
        <div className="row">
          <div className="col-12">
            <Card className="mt-8">
              <CardBody className="flex-col flex justify-center lg:flex lg:flex-row lg:justify-start items-center">
                <Icon name="warning" size={34} color="#F73434" />
                <div className="ml-4" style={{ maxWidth: '750px' }}>
                В настоящий момент проводятся BETA-тесты кошелька USDT (TRC20). 
                Настоятельно рекомендуем не использовать его для ввода и вывода крупных сумм. Обо всех проблемах просим сообщать в техподдержку.
                </div>
              </CardBody>
            </Card>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Card className="mt-8">
              <CardBody className="p-5 flex-col flex justify-center lg:flex lg:flex-row lg:justify-start items-center">
                <Icon name="info" size={34} color="#7c61bd" />
                <div className="ml-4" style={{ maxWidth: '750px' }}>
                  Если у Вас нет криптовалюты и Вы не знаете, где можно её
                  приобрести, мы рекомендуем Вам{' '}
                  <a
                    href="https://t.me/rsi_garant"
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    RSI GARANT
                  </a>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center">
            <Spinner size={40} />
          </div>
        ) : (
          <>
            <div className="mt-4">
              <Swiper
                onActiveIndexChange={e =>
                  funcPaginationSwiperChange(e.activeIndex)
                }
                spaceBetween={15}
                navigation={{
                  prevEl: '.prev',
                  nextEl: '.next',
                }}
                scrollbar={{ draggable: true }}
                observeParents={true}
                observer={true}
                slidesPerView={3}
                pagination={{ clickable: true }}
                breakpoints={{
                  1344: { slidesPerView: 3 },
                  720: { slidesPerView: 2 },
                  320: { slidesPerView: 1 },
                }}
              >
                {[...response.data].reverse().map(renderWallet)}
              </Swiper>

              <div className="swiper-btns-block mt-4">
                <div className="prev mx-2 pag flex items-center justify-center">
                  <Icon size={24} className="icon" name="arrowLeft" />
                </div>

                <div className="next mx-2 pag flex items-center justify-center">
                  <Icon size={24} className="icon" name="arrowRight" />
                </div>
              </div>
            </div>
          </>
        )}

        {selectedWallet && (
          <>
            <div className="overflow-x-auto my-8">
              {loadingAddress ? (
                <div className="flex justify-center">
                  <Spinner size={40} />
                </div>
              ) : (
                <table className="table">
                  <thead>
                    <tr>
                      <th>№</th>
                      <th>НАЗНАЧЕНИЕ</th>
                      <th className="whitespace-nowrap">ВРЕМЯ ОПЕРАЦИИ</th>
                      <th>ПОДТВЕРЖДЕНИЕ</th>
                      <th className="whitespace-nowrap">СУММА</th>
                    </tr>
                  </thead>

                  <tbody>
                    {historyList?.data?.map((i, index) => (
                      <tr key={i.id}>
                        <td>{index + 1}</td>
                        <td>{i.comment}</td>
                        <td className="whitespace-nowrap">{i.createdAt}</td>
                        <td>
                          <Badge color={i.isConfirmed ? 'primary' : 'danger'}>
                            {i.isConfirmed ? 'Подтверждено' : 'Не подтверждено'}
                          </Badge>
                        </td>
                        <td className="whitespace-nowrap">{i.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              <div className="text-center mt-2">
                <PaginationData lastPage={historyList?.lastPage} page={page} />
              </div>
            </div>
          </>
        )}
      </Container>

      <Modal
        title="Пополнить USDR"
        onClose={() => toggleModal('USDRIn')}
        isOpen={visible.USDRIn}
        footer={
          investWallet ? (
            <Button onClick={usdrToUpFromWallet} color="primary">
              Пополнить
            </Button>
          ) : null
        }
      >
        <Row>
          <Col xs={12}>
            <div className="text-lg font-medium mb-2">Откуда пополнить</div>
          </Col>

          {usdrBillList.map(renderBillBlock2)}
        </Row>

        {investWallet && investWallet === 'BTC' && (
          <>
            <Input
              label="BTC"
              name="usdrBTC"
              register={register}
              type="number"
              placeholder="Количество BTC"
              onChange={handleUsdrInBtcChange}
            />
            <span>
              Комиссия -{' '}
              {comissions[investWallet]['REPLENISH_COMMISSION'].value}%
            </span>
            <Input
              label="USDR"
              name="usdrInBtc"
              register={register}
              type="number"
              placeholder="Количество USDR"
              onChange={handleUsdrOutBtcChange}
            />
          </>
        )}

        {investWallet && investWallet === 'ETH' && (
          <>
            <Input
              label="ETH"
              name="usdrETH"
              register={register}
              type="number"
              placeholder="Количество ETH"
              onChange={handleUsdrInEthChange}
            />
            <span>
              Комиссия -{' '}
              {comissions[investWallet]['REPLENISH_COMMISSION'].value}%
            </span>
            <Input
              label="USDR"
              name="usdrInEth"
              register={register}
              type="number"
              placeholder="Количество USDR"
              onChange={handleUsdrOutEthChange}
            />
          </>
        )}

        {investWallet && investWallet === 'USDT' && (
          <>
            <Input
              label="Usdt"
              name="usdrUSDT"
              register={register}
              type="number"
              placeholder="Количество USDT"
              onChange={handleUsdrInUsdtChange}
            />
            <span>
              Комиссия -{' '}
              {comissions[investWallet]['REPLENISH_COMMISSION'].value}%
            </span>
            <Input
              label="USDR"
              name="usdrInUsdt"
              register={register}
              type="number"
              placeholder="Количество USDR"
              onChange={handleUsdrOutUsdtChange}
            />
          </>
        )} {investWallet && investWallet === 'USDT (TRC20)' && (
          <>
            <Input
              label="Usdt (TRC20)"
              name="usdrUSDTTRC20"
              register={register}
              type="number"
              placeholder="Количество USDT (TRC 20)"
              onChange={handleUsdrInUsdtTRC20Change}
            />
            <span>
              Комиссия -{' '}
              {comissions[investWallet]['REPLENISH_COMMISSION'].value}%
            </span>
            <Input
              label="USDR"
              name="usdrInUsdtTRC20"
              register={register}
              type="number"
              placeholder="Количество USDR"
              onChange={handleUsdrOutUsdtTRC20Change}
            />
          </>
        )}
        {errObj?.amount && <div className="error">{errObj.amount}</div>}
      </Modal>

      <Modal
        title="Вывести с USDR"
        onClose={() => toggleModal('USDROut')}
        isOpen={visible.USDROut}
        footer={
          investWallet &&
          (loaderSend ? (
            <Spinner size={40} />
          ) : (
            <div className="flex">
              <Button onClick={usdrOutputWallet} color="primary">
                Отправить
              </Button>
            </div>
          ))
        }
      >
        <Row>
          <Col xs={12}>
            <div className="text-lg font-medium mb-2">Куда вывести</div>
          </Col>
          {usdrBillList.map(renderBillBlock)}
        </Row>
        {investWallet && investWallet === 'BTC' && (
          <>
            <Input
              label="USDR"
              name="usdrInBtcWithCommission"
              register={register}
              type="number"
              placeholder="Количество USDR"
              onChange={handleUsdrOutBtcChangeWithComission}
            />
            {watch('UsdrOutBtc') && (
              <span>Комиссия - {watch('UsdrOutBtc')}</span>
            )}

            <Input
              label="BTC"
              name="usdrBtcWithCommission"
              register={register}
              type="number"
              placeholder="Количество BTC"
              onChange={handleUsdrInBtcChangeWithComission}
            />
          </>
        )}

        {investWallet && investWallet === 'ETH' && (
          <>
            <Input
              label="USDR"
              name="usdrInEthWithCommission"
              register={register}
              type="number"
              placeholder="Количество USDR"
              onChange={handleUsdrOutEthChangeWithComission}
            />
            {watch('UsdrOutEth') && (
              <span>Комиссия - {watch('UsdrOutEth')}</span>
            )}
            <Input
              label="ETH"
              name="usdrEthWithCommission"
              register={register}
              type="number"
              placeholder="Количество ETH"
              onChange={handleUsdrInEthChangeWithComission}
            />
          </>
        )}
        {investWallet && investWallet === 'USDT' && (
          <>
            <Input
              label="USDR"
              name="usdrInUsdtWithCommission"
              register={register}
              type="number"
              placeholder="Количество USDR"
              onChange={handleUsdrOutUsdtChangeWithComission}
            />
            {watch('UsdrOutUsdt') && (
              <span>Комиссия - {watch('UsdrOutUsdt')}</span>
            )}
            <Input
              label="Usdt"
              name="usdrUSDTWithCommission"
              register={register}
              type="number"
              placeholder="Количество USDT"
              onChange={handleUsdrInUsdtChangeWithComission}
            />
          </>
        )}
         {investWallet && investWallet === 'USDT (TRC20)' && (
          <>
            <Input
              label="USDR"
              name="usdrInUsdtTRC20WithCommission"
              register={register}
              type="number"
              placeholder="Количество USDR"
              onChange={handleUsdrOutUsdtTRC20ChangeWithComission}
            />
            {watch('UsdrOutUsdtTRC20') && (
              <span>Комиссия - {watch('UsdrOutUsdtTRC20')}</span>
            )}
            <Input
              label="USDT (TRC20)"
              name="usdrUSDTTRC20WithCommission"
              register={register}
              type="number"
              placeholder="Количество USDT (TRC20)"
              onChange={handleUsdrInUsdtTRC20ChangeWithComission}
            />
          </>
        )}
        {errObj?.amount && <div className="error">{errObj.amount}</div>}
      </Modal>

      <Modal
        title="Отправить с USDR"
        onClose={() => toggleModal('USDRSend')}
        isOpen={visible.USDRSend}
        footer={
          loaderSend ? (
            <Spinner size={40} />
          ) : (
            <Button onClick={usdrSendUsdrWallet} color="primary">
              Отправить
            </Button>
          )
        }
      >
        {userInfoById && (
          <NotifyBlock classList="mb-4 _color-primary">
            Получатель: {userInfoById}
          </NotifyBlock>
        )}

        <Input
          name="userIdUsdr"
          register={register}
          label="ID получателя"
          type="number"
          error={errObj?.recipient_id?.[0]}
        />

        <div className="mb-4">
          <Input
            name="usdrOutUsdr"
            register={register}
            label="USDR"
            type="number"
            error={errObj?.sum?.[0]}
            fieldClass="mb-0"
          />

          <div className="row justify-between mt-1">
            <div className="col-auto">
              Доступно: {parseFloat(response?.data?.[4]?.balance).toFixed(6)}
            </div>

            <div className="col-auto">
              <button
                onClick={() =>
                  setValue(
                    'usdrOutUsdr',
                    parseFloat(response?.data?.[4]?.balance).toFixed(6)
                  )
                }
                className="underline text-purple"
              >
                Макс.
              </button>
            </div>
          </div>
        </div>

        {validateUsdrSend.sms && (
          <Input
            name="smsCodeUsdr"
            register={register}
            label="Sms - код"
            type="number"
          />
        )}

        {validateUsdrSend.email && (
          <Input name="emailCodeUsdr" register={register} label="Email - код" />
        )}

        {(errObj.to || errObj.amount) && (
          <div className="error">{errObj.to || errObj.amount}</div>
        )}
      </Modal>

      <Modal
        title="Пополнить USDT"
        onClose={() => toggleModal('USDTIn')}
        isOpen={visible.USDTIn}
        footer={
          <CopyToClipboard
            onCopy={() => handleAlertOpen('Счет успешно скопирован')}
            text={response?.data?.[1]?.address}
          >
            <Button color="primary">Скопировать номер счета</Button>
          </CopyToClipboard>
        }
      >
        {response?.data?.[1] && <QRCode value={response?.data?.[1]?.address} />}
      </Modal>

      <Modal
        title="Пополнить USDT (TRC20)"
        onClose={() => toggleModal('USDTTRC20In')}
        isOpen={visible.USDTTRC20In}
        footer={
          <CopyToClipboard
            onCopy={() => handleAlertOpen('Счет успешно скопирован')}
            text={response?.data?.[2]?.address}
          >
            <Button color="primary">Скопировать номер счета</Button>
          </CopyToClipboard>
        }
      >
        {response?.data?.[2] && <QRCode value={response?.data?.[2]?.address} />}
      </Modal>

      <Modal
        title="Отправить с USDT"
        onClose={() => toggleModal('USDTSend')}
        isOpen={visible.USDTSend}
        footer={
          loaderSend ? (
            <Spinner size={40} />
          ) : (
            <Button onClick={sendWalletBalance} color="primary">
              Отправить
            </Button>
          )
        }
      >
        <Input
          name="userIdUsdt"
          register={register}
          label="Адрес получателя"
          onChange={handleUsdtIdUpdate}
          error={errObj.to?.[0]}
        />

        {commissionSendUsdt?.USDT && (
          <>
            <Input
              name="usdtOut"
              register={register}
              label="Сумма отправки"
              type="number"
              onChange={handleUsdtSend}
              error={errObj.amount}
            />
            <Input
              name="usdtOutWithComission"
              register={register}
              label="С учетом комиссии"
              type="number"
              onChange={handleUsdtSendWithComission}
            />
            <div className="mb-3">
              Приблизительная комиссия:{' '}
              {commissionSendUsdt &&
                commissionSendUsdt[selectedWallet]?.['TRANSACTION_COMMISSION']
                  .value}{' '}
              USDT
            </div>
            <div className="error">
              Внимание! Комиссия за транзакцию является ПЛАВАЮЩЕЙ и зависит от
              цены и обьема "газа". Окончательная цена будет скорректирована
              после совершения транзакции.
            </div>

            {validateSendWallet.sms && (
              <Input
                name="smsCodeValidate"
                register={register}
                label="Sms - код"
                type="number"
              />
            )}

            {validateSendWallet.email && (
              <Input
                name="emailCodeValidate"
                register={register}
                label="Код с почты"
              />
            )}
          </>
        )}
      </Modal>

      <Modal
        title="Отправить с USDT (TRC20)"
        onClose={() => toggleModal('USDTTRC20Send')}
        isOpen={visible.USDTTRC20Send}
        footer={
          loaderSend ? (
            <Spinner size={40} />
          ) : (
            <Button onClick={sendWalletBalance} color="primary">
              Отправить
            </Button>
          )
        }
      >
        <Input
          name="userIdUsdtTRC20"
          register={register}
          label="Адрес получателя"
          onChange={handleUsdtTRC20IdUpdate}
          error={errObj.to?.[0]}
        />

        {commissionSendUsdtTRC20?.USDT && (
          <>
            <Input
              name="usdtTRC20Out"
              register={register}
              label="Сумма отправки"
              type="number"
              onChange={handleUsdtTRC20Send}
              error={errObj.amount}
            />
            <Input
              name="usdtTRC20OutWithComission"
              register={register}
              label="С учетом комиссии"
              type="number"
              onChange={handleUsdtTRC20SendWithComission}
            />
            <div className="mb-3">
              Приблизительная комиссия:{' '}
              {commissionSendUsdtTRC20 &&
                commissionSendUsdtTRC20[selectedWallet]?.['TRANSACTION_COMMISSION']
                  .value}{' '}
              USDT (TRC20)
            </div>
            <div className="error">
              Внимание! Комиссия за транзакцию является ПЛАВАЮЩЕЙ и зависит от
              цены и обьема "газа". Окончательная цена будет скорректирована
              после совершения транзакции.
            </div>

            {validateSendWallet.sms && (
              <Input
                name="smsCodeValidate"
                register={register}
                label="Sms - код"
                type="number"
              />
            )}

            {validateSendWallet.email && (
              <Input
                name="emailCodeValidate"
                register={register}
                label="Код с почты"
              />
            )}
          </>
        )}
      </Modal>

      <Modal
        title="Пополнить ETH"
        onClose={() => toggleModal('ETHIn')}
        isOpen={visible.ETHIn}
        footer={
          <CopyToClipboard
            onCopy={() => handleAlertOpen('Счет успешно скопирован')}
            text={response?.data?.[0]?.address}
          >
            <Button color="primary">Скопировать номер счета</Button>
          </CopyToClipboard>
        }
      >
        {response?.data?.[0] && <QRCode value={response?.data?.[0]?.address} />}
      </Modal>

      <Modal
        title="Отправить с ETH"
        onClose={() => toggleModal('ETHSend')}
        isOpen={visible.ETHSend}
        footer={
          loaderSend ? (
            <Spinner size={40} />
          ) : (
            <Button onClick={sendWalletBalance} color="primary">
              Отправить
            </Button>
          )
        }
      >
        <Input
          name="userIdEth"
          register={register}
          label="Адрес получателя"
          onChange={handleEthIdUpdate}
          error={errObj.to?.[0]}
        />

        {commissionSendEth?.ETH && (
          <>
            <Input
              name="ethOut"
              register={register}
              label="Сумма отправки"
              type="number"
              onChange={handleEthSend}
              error={errObj.amount}
            />
            <Input
              name="ethOutWithComission"
              register={register}
              label="С учетом комиссии"
              type="number"
              onChange={handleEthSendWithComission}
            />
            <div className="mb-3">
              Приблизительная комиссия:{' '}
              {commissionSendEth &&
                commissionSendEth[selectedWallet]?.['TRANSACTION_COMMISSION']
                  .value}{' '}
              ETH
            </div>
            <div className="error">
              Внимание! Комиссия за транзакцию является ПЛАВАЮЩЕЙ и зависит от
              цены и обьема "газа". Окончательная цена будет скорректирована
              после совершения транзакции.
            </div>
            {validateSendWallet.sms && (
              <Input
                name="smsCodeValidate"
                register={register}
                label="Sms - код"
                type="number"
              />
            )}

            {validateSendWallet.email && (
              <Input
                name="emailCodeValidate"
                register={register}
                label="Код с почты"
              />
            )}
          </>
        )}
      </Modal>

      <Modal
        title="Пополнить BTC"
        onClose={() => toggleModal('BTCIn')}
        isOpen={visible.BTCIn}
        footer={
          <CopyToClipboard
            onCopy={() => handleAlertOpen('Счет успешно скопирован')}
            text={response?.data?.[3]?.address}
          >
            <Button color="primary">Скопировать номер счета</Button>
          </CopyToClipboard>
        }
      >
        {response?.data?.[3] && <QRCode value={response?.data?.[3]?.address} />}
      </Modal>

      <Modal
        title="Отправить с BTC"
        onClose={() => toggleModal('BTCSend')}
        isOpen={visible.BTCSend}
        footer={
          loaderSend ? (
            <Spinner size={40} />
          ) : (
            <Button onClick={sendWalletBalance} color="primary">
              Отправить
            </Button>
          )
        }
      >
        <Input
          name="userIdBtc"
          register={register}
          label="Адрес получателя"
          onChange={handleBtcIdUpdate}
          error={errObj.to?.[0]}
        />

        {commissionSendBtc?.BTC && (
          <>
            <Input
              name="btcOut"
              register={register}
              label="Сумма отправки"
              type="number"
              error={errObj.amount}
              onChange={handleBtcSend}
            />
            <Input
              name="btcOutWithComission"
              register={register}
              label="С учетом комиссии"
              type="number"
              onChange={handleBtcSendWithComission}
            />
            <div className="mb-3">
              Приблизительная комиссия:{' '}
              {commissionSendBtc &&
                commissionSendBtc[selectedWallet]?.['TRANSACTION_COMMISSION']
                  .value}{' '}
              BTC
            </div>
            <div className="error">
              Внимание! Комиссия за транзакцию является ПЛАВАЮЩЕЙ и зависит от
              цены и обьема "газа". Окончательная цена будет скорректирована
              после совершения транзакции.
            </div>

            {validateSendWallet.sms && (
              <Input
                name="smsCodeValidate"
                register={register}
                label="Sms - код"
                type="number"
              />
            )}

            {validateSendWallet.email && (
              <Input
                name="emailCodeValidate"
                register={register}
                label="Код с почты"
              />
            )}
          </>
        )}
      </Modal>

      <Modal
        title={alertText}
        onClose={handleAlertClose}
        isOpen={alertView}
        headerStyles={{ border: 'none' , color:`${textColor}`}}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className="flex justify-center">
            <Col xs={'auto'}>
              <Button onClick={handleAlertClose} color="primary">
                Ок
              </Button>
            </Col>
          </div>
        }
      />
    </>
  );
};
