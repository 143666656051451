/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { apiGet, apiPost } from '../../../../api';
import { appealListThemes } from '../../../../global/appealList';
import { useFetch } from '../../../../hooks/useFetch';
import useUrl from '../../../../hooks/useUrl';
import { Badge } from '../../../common/badge';
import { FileView } from '../../../common/fileView';
import { Icon } from '../../../common/icon';
import { PageHeader } from '../../../common/pageHeader';
import { Pagination } from '../../../common/pagination';
import { Button } from '../../../common/ui/button';
import { Input } from '../../../common/ui/input';
import { Modal } from '../../../common/ui/modal';
import { Select } from '../../../common/ui/select';
import './styles.scss';

export const AppealPage = () => {
  const { page } = useUrl();

  const history = useHistory();

  const profileId = useSelector(s => s.user.profile.user_id);

  const messagesEndRef = useRef(null);

  const { register, handleSubmit, setValue, watch } = useForm();

  const [modalCreate, setModalCreate] = useState(false);
  const [modalAppeal, setModalAppeal] = useState(false);
  const [errObj, setErrObj] = useState({});
  const [appealData, setAppealData] = useState({});
  const [appealId, setAppealId] = useState(null);
  const [modalCloseAppeal, setModalCloseAppeal] = useState(false);
  const [files, setFiles] = useState([]);
  const [keyInputFile, setKeyInputFile] = useState(new Date());
  const [chatFiles, setChatFiles] = useState([]);

  const openCloseAppealModal = () => setModalCloseAppeal(true);
  const closeAppealModalClose = () => setModalCloseAppeal(false);

  const { response: appealList, fetchData: refreshAppealList } = useFetch({
    url: `support?page=${page || 1}&limit=20`,
  });

  const handleModalCreateClose = () => {
    setModalCreate(false);
    setValue('text', '');
    setValue('packageType', '0');
    setFiles([]);
  };
  const handleModaCreatelOpen = () => setModalCreate(true);

  const handleModalAppealOpen = () => setModalAppeal(true);
  const handleModalAppealClose = () => setModalAppeal(false);

  const createAppeal = data => {
    if (data.packageType === '0') {
      setErrObj({ createAppeal: 'Выберите тему' });
      return;
    }

    let formData = new FormData();

    formData.append('subject', data.packageType);
    formData.append('text', data.text);
    if (files.length) {
      files.forEach(file => {
        formData.append('files[]', file);
      });
    }

    apiPost({
      url: '/support/create',
      postData: formData,
    }).then(res => {
      if (res.error) {
        setErrObj(res.data);
        return;
      }
      if (!res.error) {
        setValue('text', '');
        setValue('packageType', '0');
        setFiles([]);
        setModalCreate(false);
        setErrObj({});
        refreshAppealList();
      }
    });
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollToBottom();
  }, [modalAppeal]);

  const openAppeal = id => {
    apiGet({
      url: `/support/view?id=${id}`,
    }).then(res => {
      if (res) {
        if (res.error) {
          console.error('Ошибка загрузки сообщений');
        }
        if (!res.error) {
          setAppealId(id);
          setAppealData(res.data);
          handleModalAppealOpen();
          scrollToBottom();
        }
      }
    });
  };

  const sendMessage = () => {
    let formData = new FormData();

    formData.append('message', watch('messageSend'));
    if (chatFiles.length) {
      chatFiles.forEach(file => {
        formData.append('files[]', file);
      });
    }

    apiPost({
      url: `/support/send?id=${appealId}`,
      postData: formData,
    }).then(res => {
      if (res) {
        if (res.error) {
          setErrObj(res.data);
          console.error('Ошибка отправки сообщения');
          return;
        }
        if (!res.error) {
          setErrObj({});
          openAppeal(appealId);
          setValue('messageSend', '');
          setChatFiles([]);

          // let messages = [...appealData.messages]
          // messages.push(res.data)
          // setAppealData(appealData, (appealData.messages = messages))
        }
      }
    });
  };

  const handleUploadFiles = e => {
    if (files.length > 20) {
      return;
    }
    if (files.length < 20) {
      setFiles([...files, e.target.files[0]]);
    }
  };

  const handleUploadFilesChat = e => {
    if (chatFiles.length > 20) {
      return;
    }
    if (chatFiles.length < 20) {
      setChatFiles([...chatFiles, e.target.files[0]]);
    }
  };

  const renderFiles = (file, index) => {
    const deleteFile = () => {
      let arr = [...files];
      arr.splice(index, 1);
      setFiles(arr);
    };

    return (
      <div className="col-2" key={(file, index)}>
        <FileView upload={true} data={file} deleteFile={deleteFile} />
      </div>
    );
  };

  const renderUploadFiles = (file, index) => {
    const deleteFile = () => {
      let arr = [...chatFiles];
      arr.splice(index, 1);
      setChatFiles(arr);
    };

    return (
      <div className="col-1" key={file.name + Math.random()}>
        <FileView upload={true} data={file} deleteFile={deleteFile} />
      </div>
    );
  };

  const closeAppeal = () => {
    apiGet({
      url: `/support/close?id=${appealId}`,
    }).then(res => {
      if (res) {
        if (res.error) {
          console.error('Ошибка Закрытия чата');
        }
        if (!res.error) {
          refreshAppealList();
          setModalAppeal(false);
          closeAppealModalClose();
        }
      }
    });
  };

  useEffect(() => {
    setKeyInputFile(new Date());
  }, [files, chatFiles]);

  const renderChatFiles = file => {
    return (
      <div style={{ maxWidth: '150px' }} key={file.id}>
        <FileView download={true} fieldClass="cursor-pointer" data={file} />
      </div>
    );
  };

  return (
    <Container fluid>
      <Row className="items-center justify-between">
        <Col sm="auto">
          <PageHeader title="Обращения" />
        </Col>
        <Col sm="auto">
          <Button onClick={handleModaCreatelOpen} color="primary">
            Создать обращение
          </Button>
        </Col>
      </Row>
      <div className="overflow-x-auto my-8">
        <table className="table">
          <thead>
            <tr>
              <th>№</th>
              <th>ТЕМА</th>
              <th>ВРЕМЯ СОЗДАНИЯ</th>
              <th>ВРЕМЯ ПОСЛЕДНЕГО СООБЩЕНИЯ</th>
              <th>СТАТУС</th>
            </tr>
          </thead>
          <tbody>
            {appealList?.data?.models?.map((i, index) => (
              <tr
                key={i.id}
                style={{ cursor: 'pointer' }}
                onClick={() => openAppeal(i.id)}
              >
                <td>{index + 1}</td>
                <td>{i.subjectText}</td>
                <td>{i.createdAt}</td>
                <td>{i.lastMessageTime}</td>
                <td>
                  <Badge
                    color={
                      i.status === 0 || i.status === 1 ? 'primary' : 'danger'
                    }
                  >
                    {i.statusText}
                  </Badge>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="text-center mt-4">
          <Pagination lastPage={appealList?.data.lastPage} page={page} />
        </div>
      </div>

      <Modal
        title="Создание обращения"
        onClose={handleModalCreateClose}
        isOpen={modalCreate}
      >
        <form onSubmit={handleSubmit(createAppeal)}>
          <Row>
            <Select
              fieldClass="w-full"
              list={appealListThemes}
              name="packageType"
              register={register}
              error={errObj.createAppeal}
            />
          </Row>
          <Row>
            <div className="form-fild block w-full">
              <textarea
                rows="10"
                {...register('text')}
                className="appeal-message w-full"
              />
              <div className="error">{errObj?.text && errObj.text[0]}</div>
            </div>
          </Row>

          {files.length ? (
            <Row className="flex flex-nowrap custom-scroll-horizont overflow-auto">
              {files.map(renderFiles)}
            </Row>
          ) : null}

          <Row>
            <div className="flex mt-3">
              <Button color="primary ">Создание обращения</Button>

              <label
                className={`flex items-center ml-5  ${
                  files.length < 20 ? 'cursor-pointer' : ''
                }`}
              >
                {files.length < 20 ? (
                  <Input
                    key={keyInputFile}
                    onChange={handleUploadFiles}
                    fieldClass="mb-0"
                    inputClass="border-none"
                    type="file"
                    className="hidden"
                  />
                ) : null}

                <div>
                  <Icon
                    name="paperClip"
                    color={files.length > 19 ? 'gray' : '#000'}
                  />
                </div>
              </label>
            </div>
          </Row>
        </form>
      </Modal>
      <Modal
        title="Обращение"
        isOpen={modalAppeal}
        onClose={handleModalAppealClose}
        modalStyles={{
          maxWidth: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
        bodyStyles={{ flex: 1, overflow: 'auto' }}
        footer={
          appealData.status !== 2 && (
            <>
              <div className="flex">
                <div className="mr-2">
                  <Button
                    onClick={openCloseAppealModal}
                    className="h-full"
                    color="danger"
                  >
                    <Icon color="#fff" name="closeCircle" />
                  </Button>
                </div>
                <div className="w-full mb-0">
                  <Input
                    fieldClass={'w-full mb-0'}
                    name="messageSend"
                    register={register}
                  />
                </div>
                <div className="ml-2 flex">
                  <Button onClick={sendMessage} color="primary">
                    Отправить
                  </Button>

                  <label
                    className={`flex items-center ml-5  ${
                      chatFiles.length < 20 ? 'cursor-pointer' : ''
                    }`}
                  >
                    {chatFiles.length < 20 ? (
                      <Input
                        key={keyInputFile}
                        onChange={handleUploadFilesChat}
                        fieldClass="mb-0"
                        inputClass="border-none"
                        type="file"
                        className="hidden"
                      />
                    ) : null}

                    <div>
                      <Icon
                        name="paperClip"
                        color={chatFiles.length > 19 ? 'gray' : '#000'}
                      />
                    </div>
                  </label>
                </div>
              </div>
              {errObj?.text && <div className="error">{errObj?.text}</div>}
              <Row className="flex flex-nowrap custom-scroll-horizont overflow-auto mt-3">
                {chatFiles.map(renderUploadFiles)}
              </Row>
            </>
          )
        }
      >
        {appealData?.ticketMessages?.map((m, index) =>
          m.author_id === profileId ? (
            <div className="sms-temp sended-temp" key={index}>
              <div className="sended sms">{m.text}</div>
              <Row className="flex justify-end mt-3">
                {m?.files?.map(renderChatFiles)}
              </Row>
            </div>
          ) : (
            <div key={index + 10} className="coming sms sms-temp">
              {m.text}
            </div>
          )
        )}

        <div ref={messagesEndRef} />
      </Modal>

      <Modal
        title="Вы действительно хотите закрыть обращение?"
        onClose={closeAppealModalClose}
        isOpen={modalCloseAppeal}
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className="flex justify-center">
            <Col xs={'auto'}>
              <Button onClick={closeAppeal} color="danger">
                Да
              </Button>
            </Col>
            <Col xs={'auto'}>
              <Button onClick={closeAppealModalClose} color="primary">
                Нет
              </Button>
            </Col>
          </div>
        }
      />
    </Container>
  );
};
