import copy from 'copy-to-clipboard';
import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';
import avatarLogo from '../../../../assets/images/avatar.svg';
import { COLORS } from '../../../../global/colors';
// import { useFetch } from '../../../../hooks/useFetch';
import { Icon } from '../../../common/icon';
// import Spinner from '../../../common/spinner';
// import { StaticBlock } from '../../../common/staticBlock';
import { Button } from '../../../common/ui/button';
import { Modal } from '../../../common/ui/modal';
import './style.scss';
import PropTypes from 'prop-types';
import { GenerationsPage } from '../generations';

const DealPage = (/*{existBinary, existLinear}*/) => {
  const profile = useSelector(s => s.user.profile);

  const [alertView, setAlertView] = useState(false);

  const handleAlertOpen = () => setAlertView(true);
  const handleAlertClose = () => setAlertView(false);

  // const { response: profitData, loading: loadingProfitData } = useFetch({
  //   url: '/profit',
  // });

  const handleCopy = () => {
    copy(`https://wl.wocom.biz/registration?referral=${profile.ref}`);
    handleAlertOpen();
  };

  return (
    <Container fluid>
      <div className="sm:text-2xl text-lg my-8 font-medium">
        Кабинет партнера
      </div>

      <Card className="mb-8 p-4">
        <Row className="items-center">
          <Col>
            <Row>
              <Col xs="auto pr-0">
                <img
                  src={profile?.avatar || avatarLogo}
                  className="sm:w-20 sm:h-20 w-10 h-10 rounded-md"
                />
              </Col>

              <Col className="flex flex-col justify-between">
                <div className="text-xs text-purple">ID {profile?.user_id}</div>

                <div>
                  <div className="xl:text-2xl md:text-lg text-md font-medium">
                    {profile?.last_name} {profile?.first_name}{' '}
                    {profile?.patronymic}
                  </div>

                  <div className="price text-xl">
                    {(+profile?.balance).toFixed(2)} USDR
                  </div>
                </div>
              </Col>
            </Row>
          </Col>

          <Col xs="auto">
            <Row
              className="items-center text-purple cursor-pointer"
              onClick={handleCopy}
            >
              <Col className="md:pr-0 pr-3">
                <div className="bg-bg w-12 h-20 flex items-center justify-center rounded-md">
                  <Icon name="copy" size={20} color={COLORS.purple} />
                </div>
              </Col>

              <Col className="md:block hidden">
                <p>
                  Копировать
                  <br />
                  реферальную
                  <br />
                  ссылку  
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      {/* <Row className="mt-3">
        {loadingProfitData ? (
          <Spinner styles={{ margin: 'auto' }} />
        ) : (
          <>
            <Col sm={6} xl={3} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#48337b"
                value={profitData?.data?.dayProfitBinary}
                iconName="statHouse"
                title="Доход за сегодня бинарный"
              />
            </Col>

            <Col sm={6} xl={3} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#48337b"
                value={profitData?.data?.dayProfitLinear}
                iconName="bank"
                title="Доход за сегодня линейный"
              />
            </Col>

            <Col sm={6} xl={3} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#48337b"
                value={profitData?.data?.monthProfitBinary}
                iconName="list"
                title="Доход за месяц бинарный"
              />
            </Col>

            <Col sm={6} xl={3} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#48337b"
                value={profitData?.data?.monthProfitLinear}
                iconName="lineChart"
                title="Доход за месяц линейный"
              />
            </Col>

            <Col sm={6} xl={3} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#48337b"
                value={profitData?.data?.yearProfitBinary}
                iconName="invoice"
                title="Годовой доход бинарный"
              />
            </Col>

            <Col sm={6} xl={3} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#48337b"
                value={profitData?.data?.yearProfitLinear}
                iconName="building"
                title="Годовой доход линейный"
              />
            </Col>

            <Col sm={6} xl={3} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#48337b"
                value={profitData?.data?.totalProfitBinary}
                iconName="agenda"
                title="Общий доход бинарный"
              />
            </Col>

            <Col sm={6} xl={3} className="md:mb-8 mb-4">
              <StaticBlock
                bg="#48337b"
                value={profitData?.data?.totalProfitLinear}
                iconName="cabinet"
                title="Общий доход линейный"
              />
            </Col>
          </>
        )}
      </Row>

      <Row className="mb-8 mt-14">
        {existLinear ? <Link
          to="/linear-structure"
          className="lg:mb-0 mb-4 cursor-pointer col-sm-6 col-lg-4"
        >
          <StaticBlock
            bg="#f73434"
            value="Линейная структура"
            iconName="linearStructure"
          />
        </Link> : ''}
        {existBinary ? <Link
          to="/binary-structure"
          className="lg:mb-0 mb-4 cursor-pointer col-sm-6 col-lg-4"
        >
          <StaticBlock
            bg="#48337b"
            value="Бинарная структура"
            iconName="binaryStructure"
          />
        </Link> : ''}

        <Link
          to="/generations"
          className="lg:mb-0 mb-4 cursor-pointer col-sm-6 col-lg-4"
        >
          <StaticBlock
            bg="#f73434"
            value="Поколения"
            iconName="peopleOutline"
          />
        </Link>
      </Row> */}
      <Row>
        <GenerationsPage/>
      </Row>

      <Modal
        title="Реферальная ссылка успешно скопирована"
        onClose={handleAlertClose}
        isOpen={alertView}
        headerStyles={{ border: 'none' }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className="flex justify-center">
            <Col xs={'auto'}>
              <Button onClick={handleAlertClose} color="primary">
                Ок
              </Button>
            </Col>
          </div>
        }
      />
    </Container>
  );
};

const mapStateToProps = state => ({
  existBinary: state.user.profile.existBinary,
  existLinear: state.user.profile.existLinear
});

DealPage.propTypes = {
  existBinary: PropTypes.bool,
  existLinear: PropTypes.bool,
};


export default connect(mapStateToProps, null)(DealPage);