import React, { useEffect, useRef, useState } from 'react';
import videoPoster from '../../../../assets/images/homePoster/3.jpg';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { Icon } from '../../../common/icon';
import { Modal } from '../../../common/ui/modal';

export const PrometeyVideo = () => {
  const screenWidth = window.screen.width;
  const [videoUrl, setVideoUrl] = useState(null);
  const [isVideoVisible, setIsVideoVisible] = useState(false);

  const modalRef = useRef(null);

  const { windowWidth } = useWindowSize();

  const closeVideo = () => {
    setVideoUrl(null);
    setIsVideoVisible(false);
  };

  useEffect(() => {
    if (modalRef.current) {
      modalRef.current.style.height = `${
        modalRef.current.clientWidth * (9 / 16)
      }px`;
    }
  }, [modalRef, windowWidth]);

  const openVideo = url => {
    modalRef.current.style.height = `${
      modalRef.current.clientWidth * (9 / 16)
    }px`;

    setVideoUrl(url);
    setIsVideoVisible(true);
  };

  return (
    <>
      {/* <CardHeader>
          <div className="sm:text-2xl text-lg font-medium">
            Презентация продукта
          </div>
        </CardHeader> */}
      <button
        style={{
          borderRadius: '10px',
          overflow: 'hidden',
          boxShadow: 'rgb(8 21 66 / 25%) 0px 0px 20px',
        }}
        onClick={() =>
          openVideo('https://www.youtube.com/embed/fs2Adi1YVWs')
        }
        className=" relative"
      >
        <img alt="" src={videoPoster} />

        <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-purpleDark w-24 h-16 md:w-40 md:h-24 rounded-xl flex items-center justify-center">
          <Icon name="playButton" size={40} color="#fff" />
        </span>
      </button>

      <Modal
        isOpen={isVideoVisible}
        modalStyles={{
          maxWidth: 1280,
          width: screenWidth < 600 ? '95%' : '60%',
          margin: 'auto',
        }}
        onClose={closeVideo}
        bodyStyles={{ padding: 6, height: '100%' }}
      >
        <div ref={modalRef}>
          {videoUrl && (
            <iframe
              className="modal-video"
              src={videoUrl}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </div>
      </Modal>
    </>
  );
};
