/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { apiGet, apiPost } from '../../../../api';
import useUrl from '../../../../hooks/useUrl';
import { appLogin } from '../../../../store/app/actions';
import { Icon } from '../../../common/icon';
import Spinner from '../../../common/spinner';
import { Button } from '../../../common/ui/button';
import { Input } from '../../../common/ui/input';
// import { PhoneInput } from '../../../common/ui/input/PhoneInput';
import { AuthLayout } from '../../../layouts/auth';

export const RegistrationPage = ({ history }) => {
  const { referral } = useUrl();
  const [referalData, setReferalData] = useState(null);
  const [sendedData, setSendedData] = useState();
  const [viewPhone, setViewPhone] = useState(true);
  const [viewEmail, setViewEmail] = useState(true);

  const getRefData = () => {
    apiGet({
      url: `/auth/curator?ref=${referral}`,
    }).then(res => {
      if (res) {
        if (res.error) {
          console.error('Ошибка получения данных реферала');
          return;
        }
        if (!res.error) {
          setReferalData(res.data);
        }
      }
    });
  };

  useEffect(() => {
    if (referral) {
      getRefData();
    }
  }, []);

  const dispatch = useDispatch();

  const { register, handleSubmit, setValue, /*control*/ } = useForm();
  const [errObj, setErrObj] = useState({});
  const [validInputs, setValidInputs] = useState({
    phoneCode: false,
    emailCode: false,
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = data => {
    setLoading(true);
    const {
      email,
      password,
      passwordRepeat,
      phone,
      codePhone,
      codeEmail,
      code,
    } = data;

    if (password !== passwordRepeat) {
      setErrObj({ password: 'Пароли не совпадают' });
      setLoading(false);
      return;
    }

    let formData = new FormData();
    if (phone && viewPhone) {
      formData.append('username_phone', phone);
    }
    if (email) {
      formData.append('username', email);
    }

    if (password) {
      formData.append('password', password);
    }

    if (sendedData || codePhone) {
      if (validInputs.phoneCode) {
        formData.append('code', codePhone || '');
      }
    }

    if (sendedData || codeEmail) {
      if (validInputs.emailCode) {
        formData.append('emailCode', codeEmail || '');
      }
    }

    if (referral) {
      formData.append('ref', referral);
    } else if (code) {
      formData.append('ref', code);
    }

    setSendedData(data);

    apiPost({
      url: '/auth/signup',
      postData: formData,
    }).then(res => {
      setLoading(false);
      if (res.status === 406) {
        setErrObj(res.data);
        return;
      } else if (res.error) {
        console.error('Авторизации');
        return;
      }
      if (!res.error) {
        if (validInputs.phoneCode || validInputs.emailCode) {
          dispatch(appLogin(res.data.access_token));
          // dispatch(updateProfile(res.data.profile))
          history.push('/');
        } else {
          if (email || phone) {
            if (!email) {
              setViewEmail(false);
            }
            if (!phone) {
              setViewPhone(false);
            }
          }

          setErrObj({});
          setValidInputs({
            phoneCode: Boolean(phone && viewPhone),
            emailCode: Boolean(email),
          });
        }
      }
    });
  };

  const goBack = e => {
    e.preventDefault();

    history.goBack();
  };

  const notVerifedCode = async () => {
    setViewEmail(true);
    setViewPhone(false);
    setValidInputs({
      phoneCode: false,
      emailCode: validInputs.emailCode,
    });
    await apiPost({ url: '/auth/problem-with-sms' });

    let formData = new FormData();

    formData.append('password', sendedData?.password);
    formData.append('username', sendedData?.email);
    formData.append('phone', sendedData?.phone);

    await apiPost({ url: '/auth/signup', postData: formData }).then(res => {
      if (!res) {
        return;
      }
      if (res.status === 406) {
        setErrObj(res.data);
        return;
      }
    });
  };

  useEffect(() => {
    if (referalData) {
      setValue('code', referalData.user_id);
    }
  }, [referalData]);

  return (
    <AuthLayout>
      <Container>
        <Row className="justify-center">
          <Col md="5">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Card className="mt-8">
                <CardHeader className="py-3 text-2xl text-center font-medium">
                  <Row className="items-center">
                    <Col xs="auto" className="pr-2">
                      <button className="transform rotate-90" onClick={goBack}>
                        <Icon name="chevronDown" />
                      </button>
                    </Col>
                    <Col xs="auto">Регистрация</Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  {/* {viewPhone ? (
                    <div className="relative">
                      <PhoneInput
                        name="phone"
                        control={control}
                        label="Номер телефона"
                        // groupText="Код"
                        // groupTextAlign="right"
                        // groupTextClick={() => {}}
                        error={errObj.username_phone}
                      />
                    </div>
                  ) : null} */}
                  {viewEmail && (
                    <Input
                      name="email"
                      register={register}
                      label="E-Mail"
                      type="mail"
                      error={errObj.username}
                    />
                  )}

                  <Input
                    readOnly={referral}
                    name="code"
                    register={register}
                    label="Реферальный код пригласителя"
                    error={errObj.ref}
                  />

                  <Input
                    name="password"
                    type="password"
                    register={register}
                    label="Пароль"
                    error={errObj.password}
                  />
                  <Input
                    name="passwordRepeat"
                    type="password"
                    register={register}
                    label="Повторите пароль"
                  />


                  {validInputs.emailCode && (
                    <Input
                      name="codeEmail"
                      register={register}
                      label="Код с почты"
                      error={errObj.emailCode}
                    />
                  )}

                  {validInputs.phoneCode && (
                    <>
                      <Input
                        name="codePhone"
                        register={register}
                        label="Код с телефона"
                        error={errObj.code}
                      />

                      <div
                        onClick={notVerifedCode}
                        className="cursor-pointer text-purple"
                      >
                        Не пришел код
                      </div>
                    </>
                  )}
                </CardBody>

                <CardFooter>
                  {loading ? (
                    <Spinner size={70} />
                  ) : (
                    <Button color="primary">
                      {validInputs.emailCode || validInputs.phoneCode
                        ? 'Отправить код'
                        : 'Зарегистрироваться'}
                    </Button>
                  )}
                </CardFooter>
              </Card>
              <Row>
                <Col>
                  <div className="mt-1 text-xs text-center">
                    В случае возникновения проблем обращайтесь в поддержку на
                    электронную почту{' '}
                    <a href="mailto:it@rsi.capital?subject=Регистрация">
                      <b>it@wl.ru</b>
                    </a>
                  </div>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </AuthLayout>
  );
};
