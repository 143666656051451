import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { apiGet } from '../../../../../api';
import { TreeNode } from '../../node';

export const LinearTreeNode = ({
  parentIndex,
  openUser,
  data: { id, percent, name, totalInvest, avatar, children, isHasChildren },
}) => {
  const ref = useRef(null);
  const [childrenData, setChildrenData] = useState([]);

  const toggleChild = () => {
    apiGet({
      url: name === 'Остальные' ? '/linear/other' : `/linear/children?id=${id}`,
    }).then(res => {
      if (res) {
        if (res.error) {
          console.error('Ошибка в загрузке children');
          return;
        }
        if (!res.error) {
          setChildrenData([...res.data.children]);
          ref.current.classList.toggle('w-0');
          ref.current.classList.toggle('h-0');
        }
      }
    });
  };

  const renderChild = (i, ind) => {
    return (
      <div
        key={ind}
        className={cx('flex flex-col relative pr-6 mt-6', {
          _last: !parentIndex && ind === 2,
        })}
      >
        <LinearTreeNode
          openUser={openUser}
          parentIndex={parentIndex + 1}
          data={i}
        />
      </div>
    );
  };

  useEffect(() => {
    setChildrenData(children);
  }, [children]);

  return (
    <>
      <div
        className={cx('relative node-wrap', {
          '_has-child': isHasChildren,
        })}
      >
        <TreeNode
          id={id}
          percent={percent}
          name={name}
          totalInvest={totalInvest}
          avatar={avatar}
          totalIndex={parentIndex}
          hasChild={Boolean(isHasChildren)}
          toggleChild={() => toggleChild(name)}
          onClick={openUser}
        />
      </div>

      {childrenData && (
        <div
          ref={ref}
          className={cx('flex relative child-wrap', {
            'flex-col child-inner-wrap pl-7 w-0 h-0 overflow-hidden':
              parentIndex,
          })}
        >
          {childrenData?.map(renderChild)}
        </div>
      )}
    </>
  );
};
LinearTreeNode.propTypes = {
  parentIndex: PropTypes.number,
  openUser: PropTypes.func,
  data: PropTypes.object,
};
