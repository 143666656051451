import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { PageHeader } from '../../../common/pageHeader';

export function Flow() {
  return (
    <Container fluid>
      <Row>
        <PageHeader title="RSI flow" />

        <div className="col-12">
          <Card>
            <CardBody className="card-body">
              <Row className="xl:flex-none xl:flex-row flex flex-col-reverse items-center">
                <Col lg={12} xl={12}>
                  <p className="text-xl mb-4">
                    Мы запускаем в тестовом режиме автоследование, Вы можете
                    перейти по ссылке и создать аккаунт
                  </p>
                  <a className="link text-xl" href="https://rsiflow.com/">
                    rsiflow.com
                  </a>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>

        <div className="col-12 ">
          <Card>
            <CardBody className="card-body">
              <Row className="xl:flex-none xl:flex-row flex flex-col-reverse items-center">
                <Col lg={12} xl={12}>
                  <p className="text-xl mb-4">
                    Торговля драгоценными металлами на короткой дистанции. Наше ПО позволяет анализировать котировки золота и серебра на
                    мировых финансовых рынках. При достижении заданной торговой формации ПО отправляет сигнал для совершения сделки на МТ4. Чтобы подключить копирование сделок ознакомьтесь с <a className="link text-xl" rel="noreferrer" target='_blank' href='https://api.rsicrypto.com/docs/reg_flow.pdf'>ИНСТРУКЦИЕЙ</a> и подключайтесь по ссылке
                  </p>
                  <a className="link text-xl" href="https://my.roboforex.com/register-copyfx/?trader=160373">
                  my.roboforex.com
                  </a>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Row>
    </Container>
  );
}
