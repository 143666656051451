import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

export const BillBlock = ({ name, value, onClick, activeClass }) => {
  const handleClick = () => {
    onClick(name,value);
  };

  return (
    <div
      className={cx('cmp-bill-block mb-5', activeClass)}
      onClick={handleClick}
    >
      <div className="sm:text-lg text-md font-medium">{name}</div>
      <span className="text-price">
        {Math.trunc(value * 1000000000) / 1000000000} {name}
      </span>
    </div>
  );
};
BillBlock.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onClick: PropTypes.func,
  activeClass: PropTypes.string,
};
