import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { apiGet, apiPost } from '../../../../api';
import avatarLogo from '../../../../assets/images/avatar.svg';
import {
  reformatDateToCalendar,
  reformatDateToUser,
} from '../../../../helpers/date';
import { updateProfile } from '../../../../store/user/actions';
import { Icon } from '../../../common/icon';
import { Button } from '../../../common/ui/button';
import { Input } from '../../../common/ui/input';
import { Modal } from '../../../common/ui/modal';
import cn from 'classnames';

export const Profile = () => {
  const dispatch = useDispatch();

  const userProfile = useSelector(s => s.user.profile);

  const { register, handleSubmit, setValue } = useForm();

  const [errObj, setErrObj] = useState({});
  const [passRec, setPassRec] = useState({
    codeEmail: false,
    codePhone: false,
  });
  const [alertView, setAlertView] = useState(false);
  const [alertText, setAlertText] = useState('');

  const handleAlertOpen = text => {
    setAlertText(text);
    setAlertView(true);
  };
  const handleAlertClose = () => {
    setAlertView(false);
    setAlertText('');
  };

  const updateMessangers = data => {
    apiPost({
      url: '/profile/update-social',
      postData: { ...data },
    }).then(res => {
      if (res) {
        if (res.error) {
          setErrObj(res.data);
          return;
        }
        setErrObj({});
        handleAlertOpen('Контактные данные успешно изменены');
      }
    });
  };

  const onChangeProfile = data => {
    const { birthdate } = data;

    if (
      Math.floor(
        (new Date().getTime() - new Date(birthdate).getTime()) /
          (1000 * 3600 * 24 * 30 * 12)
      ) < 18
    ) {
      handleAlertOpen('Вам должно быть больше 18 лет');

      return;
    }

    apiPost({
      url: '/profile/update',
      postData: { ...data, birthdate: reformatDateToUser(birthdate) },
    }).then(res => {
      if (!res.error) {
        dispatch(updateProfile(res.data));
        handleAlertOpen('Профиль успешно изменен');
      }
    });
  };

  const changePassword = data => {
    if (data.password !== data.twoPass) {
      setErrObj({ password: 'Новые пароли не совпадают' });
      return;
    }

    apiPost({ url: '/user/update-password', postData: data }).then(res => {
      if (res) {
        if (res.error) {
          setErrObj(res.data);
          return;
        }
        if (!res.error) {
          if (passRec.codeEmail || passRec.codePhone) {
            setPassRec({ codeEmail: false, codePhone: false });
            setValue('emailCode', '');
            setValue('password', '');
            setValue('twoPass', '');
            setValue('newPass', '');
            setValue('code', '');
            handleAlertOpen('Пароль успешно изменен');
          } else if (!passRec.codeEmail && !passRec.codePhone) {
            setPassRec({
              codeEmail: res.data.needEmailConfirmation,
              codePhone: res.data.needPhoneConfirmation,
            });
          }
        }
      }
    });
  };

  const updateAvatarFunc = file => {
    let fileExpansion = file.name.split('.');
    let formData = new FormData();
    if (file) {
      formData.append('extension', fileExpansion[fileExpansion.length - 1]);
      formData.append('file', file);
    }
    apiPost({
      url: '/profile/upload-avatar',
      postData: formData,
    }).then(res => {
      if (res) {
        if (res.error) {
          handleAlertOpen('Неверный формат файла');
          return;
        }
        if (!res.error) {
          apiGet({
            url: '/profile',
          }).then(res => {
            if (res) {
              dispatch(updateProfile(res.data));
            }
          });
        }
      }
    });
  };

  useEffect(() => {
    const {
      birthdate,
      first_name,
      last_name,
      patronymic,
      phone,
      sk,
      fb,
      tg,
      vk,
      trading_view,
    } = userProfile;

    if (birthdate) {
      setValue('birthdate', reformatDateToCalendar(birthdate));
    }

    setValue('first_name', first_name);
    setValue('last_name', last_name);
    setValue('patronymic', patronymic);
    setValue('phone', phone);
    setValue('sk', sk);
    setValue('fb', fb);
    setValue('tg', tg);
    setValue('vk', vk);
    setValue('trading_view', trading_view || '');
  }, []);

  const profileVerified =
    userProfile.verifyStatus === 3 || userProfile.verifyStatus === 4;

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="mt-7">
          {userProfile.verifyStatus !== 3 && userProfile.verifyStatus !== 4 && (
            <Link to="/profile-verification">
              <Button color="primary" className="mb-7">
                Верифицировать профиль
              </Button>
            </Link>
          )}

          <Card>
            <CardBody>
              <div className="row items-center mb-5">
                <div className="col-auto">
                  <label className="cursor-pointer d-block">
                    <img
                      src={userProfile.avatar || avatarLogo}
                      className="rounded-full object-cover w-16 h-16"
                    />

                    <input
                      type="file"
                      onChange={e => updateAvatarFunc(e.target.files[0])}
                      className="hidden"
                    />
                  </label>
                </div>

                <div className="col">
                  <div>
                    <b>ID:</b> {userProfile?.user_id || '-'}
                  </div>

                  <div className="flex mt-1 items-center">
                    <b className="mr-2">Статус:</b>

                    <span
                      className={cn({
                        'py-1 px-4 rounded-lg bg-red text-white inline-flex items-center':
                          userProfile.verifyStatus === 5,
                      })}
                    >
                      {userProfile.verifyStatusText}
                    </span>

                    {userProfile.verifyStatus === 4 && (
                      <Icon name="check" color="#1fc410" className="ml-2" />
                    )}
                  </div>

                  <div  className="flex mt-1 items-center">
                    {userProfile.email ? <><b className="mr-2" >Email:</b> {userProfile.email} </> : <></>}
                  </div>
                    
                  <div  className="flex mt-1 items-center">
                    {userProfile.phone ? <><b className="mr-2" >Телефон:</b> {userProfile.phone} </> : <></>}
                  </div>


                  {userProfile.verifyStatus === 5 && (
                    <div className="mt-1">
                      <b>Комментарий</b>: {userProfile.verifyComment}
                    </div>
                  )}
                </div>
              </div>

              <form className="row">
                <Col lg={3}>
                  <Input
                    label="Фамилия"
                    name="last_name"
                    register={register}
                    disabled={profileVerified}
                  />
                </Col>

                <Col lg={3}>
                  <Input
                    label="Имя"
                    name="first_name"
                    register={register}
                    disabled={profileVerified}
                  />
                </Col>

                <Col lg={3}>
                  <Input
                    label="Отчество"
                    name="patronymic"
                    register={register}
                    disabled={profileVerified}
                  />
                </Col>

                <Col lg={3}>
                  <Input
                    label="Дата рождения"
                    type="date"
                    name="birthdate"
                    register={register}
                    disabled={profileVerified}
                  />
                </Col>

                {/* <Col lg={3}>
                  <Input
                    label="Номер телефона"
                    name="phone"
                    register={register}
                    disabled={profileVerified}
                  />
                </Col> */}

                {!profileVerified && (
                  <Col xs={12}>
                    <Button
                      onClick={handleSubmit(onChangeProfile)}
                      color="primary"
                    >
                      Сохранить
                    </Button>
                  </Col>
                )}
              </form>
              <div className="row">
                <Col className="my-3 lg:text-2xl text-lg font-medium">
                  Контактные данные
                </Col>
              </div>
              <form className="row" onSubmit={handleSubmit(updateMessangers)}>
                <Col lg={3}>
                  <Input
                    label="Trading View"
                    name="trading_view"
                    error={errObj?.trading_view && errObj?.trading_view[0]}
                    register={register}
                  />
                </Col>

                <Col lg={3}>
                  <Input
                    label="Skype"
                    name="sk"
                    error={errObj?.sk && errObj?.sk[0]}
                    register={register}
                  />
                </Col>

                <Col lg={3}>
                  <Input
                    label="Telegram"
                    name="tg"
                    error={errObj?.tg && errObj?.tg[0]}
                    register={register}
                  />
                </Col>

                <Col lg={3}>
                  <Input
                    label="Вконтакте"
                    name="vk"
                    error={errObj?.vk && errObj?.vk[0]}
                    register={register}
                  />
                </Col>

                <Col lg={3}>
                  <Input
                    label="Facebook"
                    name="fb"
                    error={errObj?.fb && errObj?.fb[0]}
                    register={register}
                  />
                </Col>
                <Col xs={12}>
                  <Button color="primary">Сохранить</Button>
                </Col>
              </form>
            </CardBody>
          </Card>
        </Col>

        <Col xs={12}>
          <Card>
            <CardHeader className="py-5">
              <div className="lg:text-2xl text-lg font-medium">
                Смена пароля
              </div>
            </CardHeader>

            <CardBody>
              <form className="row" onSubmit={handleSubmit(changePassword)}>
                <div className="col-md-6 col-lg-4">
                  <Input
                    label="Новый пароль"
                    type="password"
                    name="password"
                    register={register}
                    error={errObj.password}
                  />
                </div>
                <div className="col-md-6 col-lg-4">
                  <Input
                    label="Подтверждение нового пароля"
                    type="password"
                    name="twoPass"
                    register={register}
                  />
                </div>
                {passRec.codePhone && (
                  <div className="col-md-6 col-lg-4">
                    <Input
                      label="Код доступа из смс"
                      name="code"
                      register={register}
                    />
                  </div>
                )}

                {passRec.codeEmail && (
                  <div className="col-md-6 col-lg-4">
                    <Input
                      label="Код доступа c почты"
                      name="emailCode"
                      register={register}
                    />
                  </div>
                )}

                <div className="col-12">
                  <Button color="primary">
                    {!passRec.codePhone && !passRec.codeEmail
                      ? 'Отправить пин-код'
                      : 'Изменить пароль'}
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </Col>

        {/* <Col xs={12}>
					<Card>
						<CardHeader className="py-5">
							<div className="lg:text-2xl text-lg font-medium">
								Двухфакторная аутентификация
							</div>
						</CardHeader>

						<CardBody>
							<form onSubmit={handleSubmit(twoFactorAuth)}>
								<Input
									onChange={event => {
										let newPhone = event.target.value
										if (newPhone !== "" && newPhone[0] !== "+") {
											newPhone = "+" + newPhone
										}
										setPhone(newPhone)
									}}
									label="Телефон"
									value={displayValue}
									name="phone"
									register={register}
								/>
								<Button color="primary">Включить</Button>
							</form>
						</CardBody>
					</Card>
				</Col> */}
      </Row>
      <Modal
        title={alertText}
        onClose={handleAlertClose}
        isOpen={alertView}
        headerStyles={{ border: 'none' }}
        footerStyles={{ border: 'none', padding: '10px' }}
        settings="alert"
        bodyStyles={{ padding: '0px' }}
        modalStyles={{ margin: 'auto' }}
        footer={
          <div className="flex justify-center">
            <Col xs={'auto'}>
              <Button onClick={handleAlertClose} color="primary">
                Ок
              </Button>
            </Col>
          </div>
        }
      />
    </Container>
  );
};
